// https://recharts.org/en-US/examples/BiaxialLineChart
import {
  DailySubjectReportData,
  Maybe,
  OtherDataUnitEnum,
} from "generated/graphql";
import theme from "lib/theme";
import {
  XAxis,
  YAxis,
  Tooltip,
  LabelList,
  ResponsiveContainer,
  Bar,
  ComposedChart,
  LabelProps,
  Line,
  CartesianGrid,
} from "recharts";
import styled from "styled-components";
import {
  COUGH_FIELDS,
  COUGH_FILTERS,
} from "components/common/SessionsTable/ExpandedRow/Chart2";
import React, { ReactElement, useMemo } from "react";

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  /* Without this, safari will have issues sizing the chart and it does a weird thing where it pushes the table around:
    https://stackoverflow.com/a/69200646/5627036
  */
  .recharts-wrapper {
    position: absolute !important;
  }
`;

const Background = styled.div`
  position: absolute;
  top: 7.5%;
  height: 84%;
  width: 84%;
  left: 4%;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  background: ${(p) => p.theme.colors.primary10};
  z-index: 0;
  border-radius: 5px;
`;

const MAX_BAR_WIDTH = 40;

const CustomizedDot = (props: any) => {
  const { cx, cy } = props;

  return (
    <svg
      x={cx - 10}
      y={cy - 10}
      width={20}
      height={20}
      fill="green"
      viewBox="0 0 20 20"
    >
      <path d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" />
    </svg>
  );
};

const commonAxisProps = {
  tick: { fontSize: 12 },
  tickLine: false,
  axisLine: false,
  stroke: theme.colors.neutral4,
  tickCount: 4,
};

const formatNumber = (value: string) => {
  const auxNum = Number(value);
  const num = Number.isNaN(auxNum) ? 0 : auxNum;
  return num.toLocaleString("en-US", {
    maximumFractionDigits: 2,
  });
};

interface BaseChartProps {
  children: ReactElement;
}

const BaseChart: React.FC<BaseChartProps> = ({ children }) => (
  <Container>
    <Background />
    <ResponsiveContainer width="100%" height="100%">
      {children}
    </ResponsiveContainer>
  </Container>
);

interface BaseComposedChartProps extends BaseChartProps {
  data?: Maybe<Maybe<DailySubjectReportData>>[];
}

const TwentyFourHourCoughChart = ({
  data = [],
}: Pick<BaseComposedChartProps, "data">) => (
  <ComposedChart
    height={300}
    data={data}
    margin={{
      top: 32,
      bottom: 5,
      left: 24,
      right: 24,
    }}
  >
    <CartesianGrid fill={theme.colors.primary10} stroke="rgba(0,0,0,0)" />
    {data.length > 0 && (
      <Tooltip
        formatter={(value: string, name: string) => [
          formatNumber(value),
          COUGH_FIELDS[name as COUGH_FILTERS],
        ]}
        cursor={{
          stroke: "rgba(18, 127, 191,.1)",
          strokeWidth: MAX_BAR_WIDTH * 1.65,
        }}
      />
    )}
    <YAxis
      yAxisId="left"
      {...commonAxisProps}
      stroke={theme.colors.primary5}
      padding={{ top: 25 }}
      label={{
        value: "Cough Count",
        angle: -90,
        fill: theme.colors.neutral5,
        dx: -25,
      }}
      tickMargin={-3}
      tick={{ fill: theme.colors.primary2 }}
      tickFormatter={formatNumber}
    />
    <XAxis
      {...{ ...commonAxisProps, tickCount: data.length }}
      dataKey="name"
      scale="band"
    />
    <Bar
      yAxisId="left"
      dataKey="coughs"
      stackId="a"
      barSize={40}
      fill={theme.colors.primary2}
      id="coughArea"
      label={{
        position: "top",
        formatter: formatNumber,
      }}
    />
  </ComposedChart>
);

const AwakeCoughChart = ({
  data = [],
}: Pick<BaseComposedChartProps, "data">) => (
  <ComposedChart
    height={300}
    data={data}
    margin={{
      top: 32,
      bottom: 5,
      left: 24,
      right: 24,
    }}
  >
    <CartesianGrid fill={theme.colors.primary10} stroke="rgba(0,0,0,0)" />
    {data.length > 0 && (
      <Tooltip
        formatter={(_: string, name: string, props: any) =>
          name === OtherDataUnitEnum.AwakeCoughs
            ? [formatNumber(props.payload.awakeCoughs), "Awake Coughs"]
            : [formatNumber(props.payload.coughs), "24-Hour Coughs"]
        }
        cursor={{
          stroke: "rgba(18, 127, 191,.1)",
          strokeWidth: MAX_BAR_WIDTH * 1.65,
        }}
        itemSorter={(item) => (item.dataKey === "deltaCoughs" ? 0 : 1)}
      />
    )}
    <YAxis
      yAxisId="left"
      {...commonAxisProps}
      stroke={theme.colors.primary5}
      padding={{ top: 25 }}
      label={{
        value: "Cough Count",
        angle: -90,
        fill: theme.colors.neutral5,
        dx: -25,
      }}
      tickMargin={-3}
      tick={{ fill: theme.colors.primary2 }}
      tickFormatter={formatNumber}
    />
    <XAxis
      {...{ ...commonAxisProps, tickCount: data.length }}
      dataKey="name"
      scale="band"
    />
    <Bar
      yAxisId="left"
      dataKey="awakeCoughs"
      stackId="a"
      barSize={40}
      fill={theme.colors.primary5}
      id={`awakeCoughsArea`}
    >
      <LabelList
        position="insideTop"
        dataKey="awakeCoughs"
        formatter={(value: string) => {
          const numAux = Number(value);
          const num = Number.isNaN(numAux) ? 0 : numAux;
          const max = data.reduce(
            (acc, n) => Math.max(acc, n?.coughs ?? 0),
            Number.MIN_SAFE_INTEGER
          );
          return (num / max) * 100 < 5 ? "" : formatNumber(value);
        }}
      />
    </Bar>
    <Bar
      yAxisId="left"
      dataKey="deltaCoughs"
      stackId="a"
      barSize={40}
      fill={theme.colors.primary2}
      id="coughArea"
      label={{
        position: "top",
        formatter: formatNumber,
      }}
    />
  </ComposedChart>
);

const AverageHourCoughChart = ({
  data = [],
}: Pick<BaseComposedChartProps, "data">) => (
  <ComposedChart
    height={300}
    data={data}
    margin={{
      top: 32,
      bottom: 5,
      left: 24,
      right: 24,
    }}
  >
    <CartesianGrid fill={theme.colors.primary10} stroke="rgba(0,0,0,0)" />
    {data.length > 0 && (
      <Tooltip
        formatter={(_: string, name: string, props: any) =>
          name === OtherDataUnitEnum.AvgHrCoughs
            ? [
                formatNumber(props.payload.avgHrCoughs),
                "Average Cough per Hour",
              ]
            : [formatNumber(props.payload.coughs), "24-Hour Coughs"]
        }
        cursor={{
          stroke: "rgba(18, 127, 191,.1)",
          strokeWidth: MAX_BAR_WIDTH * 1.65,
        }}
      />
    )}

    <YAxis
      yAxisId="left"
      {...commonAxisProps}
      stroke={theme.colors.primary5}
      padding={{ top: 25 }}
      label={{
        value: "Cough Count",
        angle: -90,
        fill: theme.colors.neutral5,
        dx: -25,
      }}
      tickMargin={-3}
      tick={{ fill: theme.colors.primary2 }}
      tickFormatter={formatNumber}
    />
    <YAxis
      yAxisId="right"
      {...commonAxisProps}
      stroke={theme.colors.primary5}
      padding={{ top: 25 }}
      label={{
        value: "Average Coughs per Hour",
        angle: 90,
        fill: theme.colors.neutral5,
        dx: 5,
      }}
      orientation="right"
      tickMargin={-3}
      tickFormatter={formatNumber}
    />
    <XAxis
      {...{ ...commonAxisProps, tickCount: data.length }}
      dataKey="name"
      scale="band"
    />
    <Bar
      yAxisId="left"
      dataKey="coughs"
      stackId="a"
      barSize={40}
      fill={theme.colors.primary2}
      id="coughArea"
      label={{
        position: "top",
        formatter: formatNumber,
      }}
    />
    <Line
      yAxisId="right"
      type="monotone"
      dataKey="avgHrCoughs"
      stroke={theme.colors.primary5}
      activeDot={{ r: 8 }}
    />
  </ComposedChart>
);

const StepsChart = ({ data = [] }: Pick<BaseComposedChartProps, "data">) => (
  <ComposedChart
    height={300}
    data={data}
    margin={{
      top: 32,
      bottom: 5,
      left: 24,
      right: 24,
    }}
  >
    <CartesianGrid fill={theme.colors.primary10} stroke="rgba(0,0,0,0)" />
    {data.length > 0 && (
      <Tooltip
        formatter={(_: string, name: string, props: any) =>
          name === OtherDataUnitEnum.Steps
            ? [formatNumber(props.payload.steps), "Steps"]
            : [formatNumber(props.payload.coughs), "24-Hour Coughs"]
        }
        cursor={{
          stroke: "rgba(18, 127, 191,.1)",
          strokeWidth: MAX_BAR_WIDTH * 1.65,
        }}
      />
    )}
    <YAxis
      yAxisId="left"
      {...commonAxisProps}
      stroke={theme.colors.primary5}
      padding={{ top: 25 }}
      label={{
        value: "Cough Count",
        angle: -90,
        fill: theme.colors.neutral5,
        dx: -25,
      }}
      tickMargin={-3}
      tick={{ fill: theme.colors.primary2 }}
      tickFormatter={formatNumber}
    />
    <YAxis
      yAxisId="right"
      {...commonAxisProps}
      stroke={theme.colors.primary5}
      padding={{ top: 25 }}
      label={{
        value: "Steps",
        angle: 90,
        fill: theme.colors.neutral5,
        dx: 15,
      }}
      orientation="right"
      tickMargin={-3}
      tickFormatter={formatNumber}
    />
    <XAxis
      {...{ ...commonAxisProps, tickCount: data.length }}
      dataKey="name"
      scale="band"
    />
    <Bar
      yAxisId="left"
      dataKey="coughs"
      stackId="a"
      barSize={40}
      fill={theme.colors.primary2}
      id="coughArea"
      label={{
        position: "top",
        formatter: formatNumber,
      }}
    />
    <Line
      yAxisId="right"
      type="monotone"
      dataKey="steps"
      stroke={theme.colors.primary5}
      activeDot={{ r: 8 }}
    />
  </ComposedChart>
);

const MedRespRateChart = ({
  data = [],
}: Pick<BaseComposedChartProps, "data">) => (
  <ComposedChart
    height={300}
    data={data}
    margin={{
      top: 32,
      bottom: 5,
      left: 24,
      right: 24,
    }}
  >
    <CartesianGrid fill={theme.colors.primary10} stroke="rgba(0,0,0,0)" />
    {data.length > 0 && (
      <Tooltip
        formatter={(_: string, name: string, props: any) =>
          name === OtherDataUnitEnum.MedRespRate
            ? [formatNumber(props.payload.medRespRate), "Med Resp Rate"]
            : [formatNumber(props.payload.coughs), "24-Hour Coughs"]
        }
        cursor={{
          stroke: "rgba(18, 127, 191,.1)",
          strokeWidth: MAX_BAR_WIDTH * 1.65,
        }}
      />
    )}
    <YAxis
      yAxisId="left"
      {...commonAxisProps}
      stroke={theme.colors.primary5}
      padding={{ top: 25 }}
      label={{
        value: "Cough Count",
        angle: -90,
        fill: theme.colors.neutral5,
        dx: -25,
      }}
      tickMargin={-3}
      tick={{ fill: theme.colors.primary2 }}
      tickFormatter={formatNumber}
    />
    <YAxis
      yAxisId="right"
      {...commonAxisProps}
      stroke={theme.colors.primary5}
      padding={{ top: 25 }}
      label={{
        value: "Median Respiratory Rate",
        angle: 90,
        fill: theme.colors.neutral5,
        dx: 10,
      }}
      orientation="right"
      minTickGap={20}
      tickMargin={-3}
      tickFormatter={formatNumber}
    />
    <XAxis
      {...{ ...commonAxisProps, tickCount: data.length }}
      dataKey="name"
      scale="band"
    />
    <Bar
      yAxisId="left"
      dataKey="coughs"
      stackId="a"
      barSize={40}
      fill={theme.colors.primary2}
      id="coughArea"
      label={{
        position: "top",
        formatter: formatNumber,
      }}
    />
    <Line
      yAxisId="right"
      type="monotone"
      dataKey="medRespRate"
      stroke={theme.colors.primary5}
      activeDot={{ r: 8 }}
    />
  </ComposedChart>
);

const addAwakeCoughsDelta = (
  data: Maybe<Maybe<DailySubjectReportData>>[] = []
) => {
  return data.map((d) => ({
    ...d,
    deltaCoughs: (d?.coughs ?? 0) - (d?.awakeCoughs ?? 0),
  }));
};

interface Props {
  formattedData?: Maybe<Maybe<DailySubjectReportData>>[];
  isAwakeCoughs: boolean;
  chartOption?: OtherDataUnitEnum;
}

export default function SessionDataChart({
  formattedData,
  chartOption = OtherDataUnitEnum.Coughs,
}: Props) {
  const chartData = useMemo(
    () =>
      chartOption !== "awakeCoughs"
        ? formattedData
        : addAwakeCoughsDelta(formattedData),
    [chartOption, formattedData]
  );

  let chartContent = <></>;

  if (chartOption === OtherDataUnitEnum.Coughs) {
    chartContent = TwentyFourHourCoughChart({ data: chartData });
  } else if (chartOption === OtherDataUnitEnum.AwakeCoughs) {
    chartContent = AwakeCoughChart({ data: chartData });
  } else if (chartOption === OtherDataUnitEnum.AvgHrCoughs) {
    chartContent = AverageHourCoughChart({ data: chartData });
  } else if (chartOption === OtherDataUnitEnum.Steps) {
    chartContent = StepsChart({ data: chartData });
  } else if (chartOption === OtherDataUnitEnum.MedRespRate) {
    chartContent = MedRespRateChart({ data: chartData });
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      {chartContent}
    </ResponsiveContainer>
  );
}
