import "antd/lib/select/style/css";
import DropdownStyleWrapper from "components/inputs/DropdownStyleWrapper";
import { RefCallBack } from "react-hook-form";
import { DatePicker } from "antd";
import moment from "moment";

// CONSTANTS AND DESTRUCTURING
// ================================================================

interface Props {
  onChange: any;
  value: Date | undefined;
  organizationIds: string[];
  style?: React.CSSProperties;
  active?: boolean;
  placeholder?: string;
  innerRef?: RefCallBack;
}

// EXPORTED COMPONENT
// ================================================================
const SessionsStartDateSelect = ({
  organizationIds,
  active,
  style,
  innerRef,
  ...props
}: Props) => {
  const { onChange, placeholder, value, ...restProps } = props;

  return (
    <DropdownStyleWrapper style={style}>
      <DatePicker
        format={"MM/DD/YY"}
        {...restProps}
        ref={innerRef}
        size="large"
        value={value === undefined ? null : moment(value)}
        onChange={(e) => onChange(e?.toDate())}
        placeholder={placeholder || "Start Date"}
        style={{
          width: "100%",
          margin: "auto",
          ...style,
        }}
      />
    </DropdownStyleWrapper>
  );
};

// EXPORT
// ================================================================
export default SessionsStartDateSelect;
