// COMPONENTS
import PageTitle from "components/text/PageTitle";
import Tabs from "components/common/Tabs";
import Loading from "components/common/Loading";
import Text from "components/text/Text";
import Studies from "pages/admin-clients/ClientDetail/Studies";
import Users from "pages/admin-clients/ClientDetail/Users";
import Sites from "pages/admin-clients/ClientDetail/Sites";
import styled from "styled-components";

// GRAPHQL
import {
  useGetOneOrganizationQuery,
  useImpersonateOrganizationMutation,
  UserProfile,
} from "generated/graphql";

import { useHistory } from "react-router-dom";
import theme from "lib/theme";
import { LeftOutlined } from "@ant-design/icons";

const { TabPane } = Tabs;

const TextBtn = styled.button`
  border: 0px;
  background-color: ${({ theme }) => theme.colors.buttonComplete};
  padding: 0px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.white1};
  font-weight: 600;
  margin-left: 16px;
  width: 108px;
  height: 33px;
  border-radius: 8px;
`;

export default function OrganizationDetail({
  trialsId,
  organizationId,
  currentUser,
  studiesId,
  dataStudies,
  studyId,
  tabKey,
  onKeyChange,
}: {
  trialsId: string;
  studyId: string;
  organizationId: string;
  studiesId?: string;
  currentUser?: UserProfile;
  dataStudies?: any[];
  tabKey?: string;
  onKeyChange?: any;
}) {
  const history = useHistory();
  const [impersonateOrganizationMutation, { loading: impersonating }] =
    useImpersonateOrganizationMutation();
  const { data, loading, error } = useGetOneOrganizationQuery({
    variables: {
      id: organizationId,
    },
  });

  const organization = data?.getOneOrganization;

  if (loading) return <Loading />;
  if (!organizationId) return <Text>Something went wrong...</Text>;
  if (!organizationId) return null;

  const handleImpersonate = async () => {
    try {
      await impersonateOrganizationMutation({
        variables: {
          organizationId,
        },
      });
      // const open = get(window, 'open')
      // open(`${window?.location?.origin}/app`, '_blank').focus();
      console.log(`${window?.location?.origin}/app`);
      window.open(`${window?.location?.origin}/app`, "_blank")?.focus();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div style={{ display: "flex", alignItems: "center", gap: "40px" }}>
        <PageTitle
          style={{ color: theme.colors.blue16, cursor: "pointer" }}
          onClick={() => history.push(`/admin/clients`)}
        >
          <LeftOutlined style={{ marginRight: "20px" }} />
          {organization?.name}
        </PageTitle>
        <TextBtn onClick={handleImpersonate} disabled={impersonating}>
          Launch Site
        </TextBtn>
      </div>
      <div>
        <Tabs defaultActiveKey="2" activeKey={tabKey} onChange={onKeyChange}>
          {/* <TabPane tab="Details" key="1">
            <Details organization={organization} />
          </TabPane> */}

          <TabPane tab="Studies" key="2">
            <Studies
              organizationId={organizationId}
              studyId={studyId}
              onKeyChange={onKeyChange}
            />
          </TabPane>
          <TabPane tab="Sites" key="3">
            <Sites
              organizationId={organizationId}
              trialsId={trialsId}
              onKeyChange={onKeyChange}
            />
          </TabPane>
          <TabPane tab="Users" key="4">
            <Users organizationId={organizationId} onKeyChange={onKeyChange} />
          </TabPane>
        </Tabs>
      </div>
    </>
  );
}
