import Select from "antd/lib/select";
import "antd/lib/select/style/css";
// import {
//   FindSubjectSummariesQuery,
//   useFindStudiesQuery,
// } from "generated/graphql";
// import { useEffect, useMemo, useState } from "react";
import DropdownStyleWrapper from "components/inputs/DropdownStyleWrapper";
import { RefCallBack } from "react-hook-form";
import styled from "styled-components";
// import findSubjectSummaries from "ApolloClient/Queries/findSubjectSummaries";
// import { useQuery } from "@apollo/client";
import _ from "lodash";
import { Maybe } from "generated/graphql";

const DropdownStyleWrapperStyled = styled(DropdownStyleWrapper)`
  .ant-select,
  .ant-select-multiple,
  .ant-select-selector {
    min-height: ${(p) => p.style?.minHeight || "40px"};
  }
`;

// CONSTANTS AND DESTRUCTURING
// ================================================================
const Option = Select.Option;

interface Props {
  onChange: any;
  value: string[];
  organizationIds: string[];
  dataArray: (Maybe<string> | undefined)[];
  style?: React.CSSProperties;
  active?: boolean;
  loading?: boolean;
  placeholder?: string;
  innerRef?: RefCallBack;
}

// EXPORTED COMPONENT
// ================================================================
const SessionsMultiSelect = ({
  organizationIds,
  active,
  style,
  innerRef,
  dataArray,
  loading,
  ...props
}: Props) => {
  const { onChange, placeholder, value = [], ...restProps } = props;
  const displayable = value.filter(
    (id) => dataArray?.find((value) => value === id) !== undefined
  );
  let dataFilterable = dataArray?.map((value) => value)?.sort();
  return (
    <DropdownStyleWrapperStyled style={style}>
      <Select
        {...restProps}
        ref={innerRef}
        size="large"
        onChange={(e) => onChange(e)}
        optionFilterProp="children"
        placeholder={placeholder || "Session ID"}
        loading={loading}
        value={displayable}
        mode="tags"
        style={{
          width: "100%",
          margin: "auto",
          ...style,
        }}
        maxTagCount="responsive"
      >
        {!loading &&
          dataFilterable
            ?.sort((a, b) => {
              const filtered1 = value.findIndex((entry) => entry === a);
              const filtered2 = value.findIndex((entry) => entry === b);
              if (filtered1 < 0 && filtered2 < 0) {
                return 0;
              } else if (filtered1 < 0) {
                return 1;
              } else if (filtered2 < 0) {
                return -1;
              }
              return 1;
            })
            ?.map((trial, index) => {
              return (
                <Option key={index} value={trial}>
                  {trial}
                </Option>
              );
            })}
      </Select>
    </DropdownStyleWrapperStyled>
  );
};

// EXPORT
// ================================================================
export default SessionsMultiSelect;
