import { CloseCircleFilled } from "@ant-design/icons";
import { Select } from "antd";
import theme from "lib/theme";

interface Props {
  setSelectedKeys: any;
  selectedKeys: any[];
  confirm: any;
  clearFilters: any;
  dataSource: any[];
  placeHolder?: string;
}

const index = ({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
  dataSource,
  placeHolder,
}: Props) => {
  const handleReset = (clearFilter: any) => {
    clearFilter();

    setSelectedKeys(clearFilters);
  };
  const Option = Select.Option;

  return (
    <div style={{ width: 250, position: "relative" }}>
      <div
        style={{
          position: "absolute",
          right: 13,
          bottom: 38,
          height: 50,
          width: 200,
          background: "white",
          borderRadius: 10,
        }}
      >
        <Select
          autoFocus={true}
          mode="tags"
          value={selectedKeys}
          onChange={(val) => {
            setSelectedKeys(val);
            confirm(confirm);
          }}
          filterOption={(selectedKeys, option: any) => {
            return (
              option.key.toLowerCase().indexOf(selectedKeys.toLowerCase()) === 0
            );
          }}
          placement="topLeft"
          placeholder={placeHolder}
          style={{
            position: "absolute",
            right: 10,
            bottom: 6,
            width: 180,
          }}
          optionFilterProp="children"
        >
          {dataSource?.map((site: any) => {
            return (
              <Option key={site?.name} value={site?.name} title={site?.name}>
                {site?.name}
              </Option>
            );
          })}
        </Select>
        {selectedKeys?.[0] && (
          <CloseCircleFilled
            onClick={() => {
              clearFilters && handleReset(clearFilters);
              confirm();
            }}
            style={{
              position: "absolute",
              right: 16,
              bottom: 15,
              color: theme.colors.neutral5,
              fontSize: 16,
              cursor: "pointer",
            }}
          />
        )}
      </div>
    </div>
  );
};

export default index;
