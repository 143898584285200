import { gql } from "@apollo/client";

export default gql`
  query GetSessionReportFiles($sessionId: ID!) {
    getSessionReportFiles(sessionId: $sessionId) {
      fileName
      content
    }
  }
`;
