import React from "react";
import styled from "styled-components";
import { CloseCircleFilled } from "@ant-design/icons";

const ErrorBlockContainer = styled.div`
  background: ${(p) => p.theme.colors.red10};
  border-left: 4px solid ${(p) => p.theme.colors.red3};
  padding: 8px;
  border-radius: 5px;
  color: ${(p) => p.theme.colors.red2};
  font-size: 14px;
  margin-bottom: 16px;
  display: flex;
  position: relative;
`;

export default function ErrorBlock({
  errors = [],
  error,
  style,
}: {
  errors?: string[];
  error?: string;
  style?: React.CSSProperties;
}) {
  if (!error && errors.length === 0) return null;

  return (
    <ErrorBlockContainer style={style}>
      <CloseCircleFilled
        style={{ marginRight: 6, fontSize: 16, position: "relative", top: 2 }}
      />
      {errors && errors.map((item) => item)}
      {error && error}
    </ErrorBlockContainer>
  );
}
