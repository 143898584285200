import styled from "styled-components";
import { ReactChildren, ReactChild } from "react";
import { UserProfile } from "generated/graphql";
import Header from "./Header";

// STYLE-COMPONENTS
// ===================================
const Container = styled.div`
  min-height: 100vh;
  display: flex;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.background};
`;

const Content = styled.div`
  padding-top: 32px;
  width: 1400px;
  margin: auto;
  max-width: 90%;
`;

// EXPORT
// ===================================
export default function PublicLayout({
  children,
  currentUser,
}: {
  children: ReactChild | ReactChildren;
  currentUser: UserProfile;
}) {
  return (
    <Container id="client-layout">
      <div style={{ width: "100%" }}>
        <Header currentUser={currentUser} />
        <Content>{children}</Content>
      </div>
    </Container>
  );
}
