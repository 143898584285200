import styled from "styled-components";

const PageTitle = styled.h1`
  margin: 0px;
  bottom: 0;
  font-weight: 400;
  line-height: 145%;
  font-family: ${(p) => p.theme.fontFamily};
  display: ${(p) => (p?.style?.display ? p?.style?.display : "block")};
  color: ${({ theme, color }) => (color ? color : theme.colors.neutral4)};
  font-size: ${(p) => (p?.style?.fontSize ? p.style.fontSize : "24px")};
`;

export default PageTitle;
