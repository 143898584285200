import theme from "lib/theme";

export default function PageCount({
  pagination,
  lastResult,
}: {
  pagination: any;
  lastResult: number;
}) {
  return (
    <span
      style={{
        position: "relative",
        top: -48,
        left: 2,
        fontSize: 14,
        color: theme.colors.neutral5,
      }}
    >
      {`${
        pagination?.current * pagination?.pageSize - pagination?.pageSize + 1
      }-${
        lastResult > pagination?.total ? pagination?.total : lastResult
      }`}{" "}
      of {pagination?.total} Results
    </span>
  );
}
