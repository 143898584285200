import React from "react";
import styled from "styled-components";

const StudiesCard = styled.button<{ style?: React.CSSProperties }>`
  border: 0px;
  margin-bottom: 5px;
  background-color: ${({ theme }) => theme.colors.gray3};
  padding: 0px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.black};
  font-weight: 600;
  font-size: 13px;
  min-width: 114px;
  padding: 0 10px;
  min-height: 23px;
  margin-right: 20px;
  border-radius: 3px;
`;
export default StudiesCard;
