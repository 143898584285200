import { SessionLogTable } from "components/tables/SessionLogTable";
import { BaseCard } from "components/tables/AnalysisSessionTable/card-elements";
import theme from "lib/theme";
import { SessionLog, useGetSessionLogsQuery } from "generated/graphql";

interface Props {
  completed?: number;
  tasks?: number;
  deviceSessionId: string;
}

export const SessionUpdateLogCard = ({
  completed = 0,
  tasks = 0,
  deviceSessionId,
}: Props) => {
  const { data } = useGetSessionLogsQuery({ variables: { deviceSessionId } });
  const logs =
    data?.getSessionLogs?.filter(
      (ele): ele is SessionLog => ele !== undefined
    ) ?? [];
  return (
    <BaseCard
      title={`Session Update Log (${logs.length})`}
      titleColor={theme.colors.gray1}
    >
      <SessionLogTable completed={completed} tasks={tasks} logs={logs} />
    </BaseCard>
  );
};
