const IconChartCard = ({
  pieChartQtr,
  pieChartHalf,
  pieChartTQtr,
  reviewed,
  completed,
  underReview,
}: {
  pieChartHalf?: boolean;
  pieChartQtr?: boolean;
  pieChartTQtr?: boolean;
  reviewed?: boolean;
  completed?: boolean;
  underReview?: boolean;
}) => {
  return (
    <>
      {pieChartQtr ? (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 20C4.47967 19.994 0.00606237 15.5204 0 10V9.80002C0.109931 4.30455 4.63459 -0.0720257 10.1307 0.000898217C15.6268 0.0738221 20.0337 4.5689 19.9978 10.0654C19.9619 15.5618 15.4966 19.9989 10 20ZM2 10.172C2.0475 14.5903 5.66772 18.1335 10.086 18.086C14.5043 18.0385 18.0475 14.4183 18 10H10V2.00002C5.58401 2.00553 2.00551 5.58402 2 10V10.172Z"
            fill="#F2994A"
          />
        </svg>
      ) : null}
      {pieChartHalf ? (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C19.9939 15.5203 15.5203 19.9939 10 20ZM9.984 18H10V2C5.58172 1.99558 1.99642 5.57372 1.992 9.992C1.98758 14.4103 5.56572 17.9956 9.984 18Z"
            fill="#F2C94C"
          />
        </svg>
      ) : null}{" "}
      {pieChartTQtr ? (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 20C4.47967 19.994 0.00606237 15.5204 0 10V9.80002C0.109931 4.30455 4.63459 -0.0720257 10.1307 0.000898217C15.6268 0.0738221 20.0337 4.5689 19.9978 10.0654C19.9619 15.5618 15.4966 19.9989 10 20ZM10 2.00002C5.58355 2.00443 2.00441 5.58357 2 10H10V2.00002Z"
            fill="#27AE60"
          />
        </svg>
      ) : null}
      {reviewed ? (
        <svg
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="10.5"
            cy="10.5"
            r="10.5"
            fill="#1992D4"
            fillOpacity="0.76"
          />
        </svg>
      ) : null}
      {completed ? (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 20C4.47967 19.994 0.00606237 15.5204 0 10V9.80002C0.109931 4.30455 4.63459 -0.0720257 10.1307 0.000898217C15.6268 0.0738221 20.0337 4.5689 19.9978 10.0654C19.9619 15.5618 15.4966 19.9989 10 20ZM5.41 9.59002L4 11L8 15L16 7.00002L14.59 5.58002L8 12.17L5.41 9.59002Z"
            fill="#50ACDE"
          />
        </svg>
      ) : null}
      {underReview ? (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 20C4.47967 19.994 0.00606237 15.5204 0 10V9.80002C0.109931 4.30455 4.63459 -0.0720257 10.1307 0.000898217C15.6268 0.0738221 20.0337 4.5689 19.9978 10.0654C19.9619 15.5618 15.4966 19.9989 10 20ZM10 2.00002C5.58355 2.00443 2.00441 5.58357 2 10H10V2.00002Z"
            fill="#27AE60"
          />
        </svg>
      ) : null}
    </>
  );
};

export default IconChartCard;
