import styled from "styled-components";

const ModalTitle = styled.span`
  margin: 0px;
  bottom: 0;
  font-weight: 600;
  line-height: 135%;
  font-family: ${(p) => p.theme.fontFamily};
  display: ${(p) => (p?.style?.display ? p?.style?.display : "block")};
  color: ${(p) => (p.color ? p.color : p.theme.colors.neutral3)};
  margin-bottom: 8px;
  font-size: ${(p) => (p?.style?.fontSize ? p.style.fontSize : "16px")};
`;

export default ModalTitle;
