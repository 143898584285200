import { TableColumnsType } from "antd";
import { format } from "date-fns";
import PrimaryButton from "components/common/Button";
import { DATE_DISPLAY_FORMAT } from "components/tables/AnalysisSessionTable/data";
import theme from "lib/theme";
import styled from "styled-components";
import { Session } from "generated/graphql";

interface AvailableSessionModel {
  key: string;
  session: string;
  device: string;
  sessionStart: Date;
  sessionStop: Date;
  uploadId: string;
  id: string;
}

export const MOCKED_DATA: AvailableSessionModel[] = [
  {
    key: "1-mock",
    session: "61234123",
    device: "CLW39868752",
    sessionStart: new Date(),
    sessionStop: new Date(),
    uploadId: "CLW19669209-CA-2022-07-19T17-57-55",
    id: "1-mock",
  },
];

interface TableColumnsProps {
  onAnalyseClick: (uploadId: string, sessionId: string) => void;
  onArchiveClick: (sessionId: string) => void;
}

export const TABLE_COLUMNS = ({
  onAnalyseClick = (_, __) => void { _, __ },
  onArchiveClick = (_) => void _,
}: TableColumnsProps): TableColumnsType<AvailableSessionModel> => [
  {
    title: "Analyze",
    render(_, { id, uploadId }) {
      return (
        <PrimaryButton
          orange
          onClick={() => onAnalyseClick(uploadId, id)}
          style={{ width: "120px" }}
        >
          Analyze
        </PrimaryButton>
      );
    },
    width: "11.7%",
  },
  {
    title: "Session",
    dataIndex: "session",
    key: "session",
    align: "center",
    width: "19%",
  },
  {
    title: "Device",
    dataIndex: "device",
    key: "device",
    align: "center",
    width: "8%",
  },
  {
    title: "Session Start",
    dataIndex: "sessionStart",
    key: "sessionStart",
    render(sessionStart: Date, record) {
      try {
        return format(sessionStart, DATE_DISPLAY_FORMAT);
      } catch (error) {
        console.debug({ record, error });
        return "invalid";
      }
    },
    align: "center",
    width: "19%",
  },
  {
    title: "Session Stop",
    dataIndex: "sessionStop",
    key: "sessionStop",
    render(sessionStop: Date, record) {
      try {
        return format(sessionStop, DATE_DISPLAY_FORMAT);
      } catch (error) {
        console.debug({ record, error });
        return "invalid";
      }
    },
    align: "center",
    width: "11.7%",
  },
  {
    title: "Upload ID",
    dataIndex: "uploadId",
    key: "uploadId",
    align: "center",
    width: "18%",
  },
  {
    title: "Archive",
    render(_, { id }) {
      return (
        <PrimaryButton
          greyActive
          onClick={() => onArchiveClick(id)}
          style={{ width: "120px" }}
        >
          Archive
        </PrimaryButton>
      );
    },
    align: "center",
  },
];

export const ARCHIVED_MOCKED_DATA: AvailableSessionModel[] = [
  {
    key: "1-archived",
    session: "6123412d",
    device: "CLW39868752",
    sessionStart: new Date(),
    sessionStop: new Date(),
    uploadId: "CLW19669209-CA-2022-07-19T17-57-55",
    id: "1-archived",
  },
];

const StyledArchivedText = styled.span`
  font-family: ${theme.fontFamily};
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
`;

interface ArchivedTableColumnsProps {
  onUnarchiveClick: (sessionId: string) => void;
}

export const ARCHIVED_TABLE_COLUMNS = ({
  onUnarchiveClick = (_) => void _,
}: ArchivedTableColumnsProps): TableColumnsType<AvailableSessionModel> => [
  {
    key: "archived",
    render() {
      return <StyledArchivedText>Archived</StyledArchivedText>;
    },
    title: "Archived",
    align: "left",
    width: "11.7%",
  },
  {
    dataIndex: "session",
    key: "session",
    title: "Session",
    align: "center",
    width: "19%",
  },
  {
    dataIndex: "device",
    key: "device",
    title: "Device",
    align: "center",
    width: "8%",
  },
  {
    dataIndex: "sessionStart",
    key: "sessionStart",
    render(sessionStart: Date, record) {
      try {
        return format(sessionStart, DATE_DISPLAY_FORMAT);
      } catch (error) {
        console.debug({ record, error });
        return "invalid";
      }
    },
    title: "Session Start",
    align: "center",
    width: "19%",
  },
  {
    dataIndex: "sessionStop",
    key: "sessionStop",
    render(sessionStop: Date, record) {
      try {
        return format(sessionStop, DATE_DISPLAY_FORMAT);
      } catch (error) {
        console.debug({ record, error });
        return "invalid";
      }
    },
    title: "Session Stop",
    align: "center",
    width: "11.7%",
  },
  {
    dataIndex: "uploadId",
    key: "uploadId",
    title: "Upload ID",
    align: "center",
    width: "18%",
  },
  {
    key: "unarchive",
    render(_, { id }) {
      return (
        <PrimaryButton
          greyActive
          onClick={() => onUnarchiveClick(id)}
          style={{ width: "120px" }}
        >
          Unarchive
        </PrimaryButton>
      );
    },
    title: "Unarchive",
    align: "center",
  },
];

export const transformSessionToAvailableSessionModel = (
  session: Session
): AvailableSessionModel => {
  const rawIndex = session.index?.toString() as string;
  let sessionIndex = "";

  if (rawIndex?.length > 4) {
    sessionIndex = rawIndex;
  } else if (rawIndex?.length !== 0) {
    sessionIndex = rawIndex.padStart(4, "0");
  }

  return {
    key: `${sessionIndex}-available`,
    session: sessionIndex,
    device: session.deviceId as string,
    sessionStart: new Date(parseInt(session.startDate)),
    sessionStop: new Date(parseInt(session.endDate)),
    uploadId: session.uploadId as string,
    id: session.id as string,
  };
};

export const transformSessionsToAvailableSessionModels = (
  sessions: Session[]
): AvailableSessionModel[] =>
  sessions.map(transformSessionToAvailableSessionModel);
