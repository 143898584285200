import { useLocation } from "react-router-dom";
import styled from "styled-components";
// COMPONENTS
import AppNav from "./AppNav";

const Container = styled.div`
  margin: auto;
  width: 1400px;
  max-width: 90%;
  padding-top: 16px;
  padding-bottom: 56px;
  min-height: calc(100vh);
`;

// Background

const Background = styled.div`
  background: ${(p) => p.theme.colors.neutral10};
`;

export default function AdminLayout({ currentUser, children }: any) {
  const location = useLocation();
  return (
    <Background>
      {/* <Header currentUser={currentUser} /> */}
      <AppNav pathname={location.pathname} />
      <Container>{children}</Container>
    </Background>
  );
}

// import { useLocation, Link, useHistory } from "react-router-dom";
// import styled from "styled-components";
// // COMPONENTS
// import Row from "components/common/Row";
// import Col from "components/common/Col";
// import InactiveModal from "components/common/InactiveModal";
// // LIB
// import logo from "lib/media/logo.png";
// import { useLogoutMutation } from "generated/graphql";
// // APOLLO
// import CURRENT_USER from "ApolloClient/Queries/currentUser";
// import client from "ApolloClient/index";
// // LIB
// import config from "lib/config";
// import removeLocalStorage from "lib/helpers/removeLocalStorage";

// const Container = styled.div`
//   margin: auto;
//   max-width: 100%;
//   min-height: 94vh;
//   background: ${(p) => p.theme.colors.neutral10};
//   padding-top: 16px;
// `;

// const InnerContainer = styled.div`
//   margin: auto;
//   width: 1150px;
//   max-width: 100%;
// `;

// const NavContainer = styled.div`
//   height: 60px;
//   border-bottom: 1px solid ${(p) => p.theme.colors.neutral9};
//   display: flex;
//   align-items: center;
// `;

// const NavMenu = styled.nav`
//   display: flex;
// `;

// const MenuLink = styled(Link)<{ active?: boolean }>`
//   font-weight: 600;
//   flex: 1;
//   text-align: center;
//   cursor: pointer;
//   color: ${(p) =>
//     p.active ? p.theme.colors.primary6 : p.theme.colors.neutral5} !important;
//   &:hover {
//     color: ${(p) =>
//       p.active ? p.theme.colors.primary4 : p.theme.colors.neutral2} !important;
//   }
// `;

// const AvatarContainer = styled.div<{ active?: boolean }>`
//   font-weight: 600;
//   flex: 1;
//   text-align: center;
//   cursor: pointer;
//   color: ${(p) =>
//     p.active ? p.theme.colors.primary6 : p.theme.colors.neutral5} !important;
//   &:hover {
//     color: ${(p) =>
//       p.active ? p.theme.colors.primary4 : p.theme.colors.neutral2} !important;
//   }
// `;

// const Logo = styled.img`
//   height: 30px;
//   cursor: pointer;
// `;

// const Nav = ({ pathname, currentUser, onSignout }: any) => {
//   const history = useHistory();
//   return (
//     <NavContainer>
//       <Row style={{ width: 1150, margin: "auto" }}>
//         <Col xs={4}>
//           <Logo src={logo} alt="logo" onClick={() => history.push("/admin")} />
//         </Col>
//         <Col xs={10}></Col>
//         <Col xs={10}>
//           <NavMenu>
//             <MenuLink to={"/admin"} active={pathname === "/admin"}>
//               Home
//             </MenuLink>
//             <MenuLink
//               to={"/admin/clinics"}
//               active={pathname === "/admin/clinics"}
//             >
//               Clients
//             </MenuLink>
//             <MenuLink to={"/admin/users"} active={pathname === "/admin/users"}>
//               Users
//             </MenuLink>
//             <MenuLink to={"/admin/logs"} active={pathname === "/admin/logs"}>
//               Logs
//             </MenuLink>
//             <AvatarContainer onClick={onSignout}>Sign Out</AvatarContainer>
//           </NavMenu>
//         </Col>
//       </Row>
//     </NavContainer>
//   );
// };

// export default function AdminLayout({ currentUser, children }: any) {
//   const history = useHistory();
//   const location = useLocation();
//   const [logoutMutation] = useLogoutMutation();
//   const onSignout = async () => {
//     try {
//       // run logout function
//       await logoutMutation();

//       // remove tokens from local storage
//       removeLocalStorage(config.authTokenName);
//       removeLocalStorage(config.refreshTokenName);
//       // rerun the user query
//       await client.query({
//         query: CURRENT_USER,
//       });
//       setTimeout(() => {
//         history.push(`/login`);
//       }, 3000);
//     } catch (err) {
//       return console.log(err);
//     }
//   };

//   return (
//     <>
//       <Nav
//         pathname={location.pathname}
//         onSignout={onSignout}
//         currentUser={currentUser}
//       />
//       <Container className="fs-exclude">
//         <InnerContainer>{children}</InnerContainer>
//       </Container>
//       <InactiveModal />
//     </>
//   );
// }
