import { Card, Row } from "antd";
import theme from "lib/theme";
import { PropsWithChildren } from "react";
import styled from "styled-components";

export const CardSubTitle = styled.span`
  font-family: ${theme.fontFamily};
  color: ${theme.colors.gray1};
  font-weight: 700;
  font-size: 12px;
  line-height: 14.52px;
`;

const TitleContainer = styled.div<{ color?: string }>`
  text-align: center;
  ${(p) => (p.color ? `color: ${p.color};` : "")}
`;

interface CardTitleProps {
  text: string;
  color?: string;
}

export const CardTitle = ({ text, color }: CardTitleProps) => (
  <TitleContainer color={color}>
    <strong>{text}</strong>
  </TitleContainer>
);

// interface BaseCardProps {
//   title: string;
//   titleColor?: string;
//   createIssueButton?: any;
// }
interface BaseCardProps {
  title: string;
  titleColor?: string;
  children?: any;
  createIssueButton?: any;
}

export const BaseCard = ({
  title,
  titleColor,
  children,
  createIssueButton,
}: BaseCardProps) => (
  // PropsWithChildren<BaseCardProps>
  <Card
    title={
      <Row align="middle" justify="center">
        <CardTitle text={title} color={titleColor} />
        {createIssueButton}
      </Row>
    }
    bodyStyle={{
      height: "180px",
      maxHeight: "180px",
      width: "100%",
      maxWidth: "100%",
      padding: 0,
    }}
    style={{ background: "rgba(217, 217, 217, 0.09)" }}
    size="small"
  >
    {children}
  </Card>
);

export const CardRegularText = styled.span`
  font-family: ${theme.fontFamily};
  color: #000;
  font-weight: 400;
  font-size: 15px;
`;

export const CardBoldText = styled.span`
  font-family: ${theme.fontFamily};
  color: ${theme.colors.gray1};
  font-weight: 600;
  font-size: 15px;
`;
