import { CloseCircleFilled } from "@ant-design/icons";
import { Select } from "antd";
import theme from "lib/theme";

interface Props {
  setSelectedKeys: any;
  selectedKeys: any[];
  confirm: any;
  clearFilters: any;
  dataSource: any[];
  placeHolder?: string;
}

const index = ({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
  dataSource,
  placeHolder,
}: Props) => {
  const handleReset = (clearFilter: any) => {
    clearFilter();

    setSelectedKeys(clearFilters);
  };
  const Option = Select.Option;
  const studyResponsiblityChain = (record: Object | any) => {
    // fields names should be sorted in the order of
    // the responsibility
    const fields = [
      "subjectId",
      "contactName",
      "site",
      "study",
      "firstName",
      "lastName",
    ];

    // You iterate through the fields names
    for (const f of fields) {
      // Then you check if the field is defined
      if (record[f] && record.contactName !== null) {
        // At first true, you return your component
        return (
          <Option key={record[f]} value={record[f]} title={record[f]}>
            {record[f]}
          </Option>
        );
      }
    }

    return null;
  };

  return (
    <div style={{ width: 250, position: "relative" }}>
      <div
        style={{
          position: "absolute",
          right: 13,
          bottom: 38,
          height: 50,
          width: 200,
          background: "white",
          borderRadius: 10,
        }}
      >
        <Select
          autoFocus={true}
          mode="tags"
          value={selectedKeys}
          onChange={(val) => {
            setSelectedKeys(val);
            confirm(confirm);
          }}
          filterOption={(selectedKeys, option: any) => {
            return (
              option.key.toLowerCase().indexOf(selectedKeys.toLowerCase()) === 0
            );
          }}
          placement="topLeft"
          placeholder={placeHolder}
          style={{
            position: "absolute",
            right: 10,
            bottom: 6,
            width: 180,
          }}
          optionFilterProp="children"
        >
          {dataSource &&
            [...dataSource]
              ?.sort((a, b) => {
                const filtered1 =
                  selectedKeys?.findIndex &&
                  selectedKeys?.findIndex((entry) => entry === a?.subjectId);
                const filtered2 =
                  selectedKeys?.findIndex &&
                  selectedKeys?.findIndex((entry) => entry === b?.subjectId);
                if (filtered1 < 0 && filtered2 < 0) {
                  return 0;
                } else if (filtered1 < 0) {
                  return 1;
                } else if (filtered2 < 0) {
                  return -1;
                }
                return 1;
              })
              ?.map((item: Object | any) => {
                return studyResponsiblityChain(item);
              })}
        </Select>
        {selectedKeys?.[0] && (
          <CloseCircleFilled
            onClick={() => {
              clearFilters && handleReset(clearFilters);
              confirm();
            }}
            style={{
              position: "absolute",
              right: 16,
              bottom: 15,
              color: theme.colors.neutral5,
              fontSize: 16,
              cursor: "pointer",
            }}
          />
        )}
      </div>
    </div>
  );
};

export default index;
