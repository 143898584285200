import styled from "styled-components";
// COMPONENTS
import { UserOutlined } from "@ant-design/icons";
import { Menu, Dropdown, message } from "antd";
import { useLogoutMutation, UserProfile } from "generated/graphql";
import removeLocalStorage from "lib/helpers/removeLocalStorage";
import config from "lib/config";
import client from "ApolloClient/index";
import { useHistory } from "react-router-dom";
import Text from "components/text/Text";
import Caption from "components/text/Caption";
import theme from "lib/theme";

const AvatarContainer = styled.div<{
  background?: string;
  height?: string;
  width?: string;
}>`
  height: ${({ height }) => height || "40px"};
  width: ${({ width }) => width || "40px"};
  border-radius: 50%;
  background: ${({ theme, background }) => background || theme.colors.primary5};
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  .anticon {
    margin-right: 0px;
  }
`;

const Avatar = styled(UserOutlined)`
  margin: 0px;
  color: ${(p) => p.theme.colors.primary10};
  font-size: 22px;
`;

export default function AvatarDropdown({
  currentUser,
}: {
  currentUser?: UserProfile;
}) {
  const [logout] = useLogoutMutation();
  const history = useHistory();
  const handleOnLogout = async () => {
    try {
      await logout();
      removeLocalStorage(config.authTokenName);
      removeLocalStorage(config.refreshTokenName);
      await client.resetStore();
      // let user know
      message.info("You've successfully logged out...");
      history.push(`/login`);
    } catch (err) {}
  };

  const menu = (
    <Menu style={{ width: 220 }}>
      <div
        style={{
          padding: 14,
          display: "flex",
          borderBottom: `1px solid ${theme.colors.neutral9}`,
          marginBottom: 4,
        }}
      >
        <AvatarContainer
          width={"30px"}
          height={"30px"}
          style={{ marginRight: 8 }}
          background={theme.colors.neutral9}
        >
          <UserOutlined style={{ color: theme.colors.neutral4 }} />
        </AvatarContainer>
        <div style={{}}>
          <Text>
            {currentUser?.firstName} {currentUser?.lastName}
          </Text>
          <Caption>{currentUser?.organizationName}</Caption>
        </div>
      </div>
      <Menu.Item key="logout" onClick={handleOnLogout}>
        Log out
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown overlay={menu}>
      <AvatarContainer>
        <Avatar />
      </AvatarContainer>
    </Dropdown>
  );
}
