import { useState } from "react";
import Papa from "papaparse";
import { get } from "lodash";

export default function useDownloadCSV() {
  const [downloadingCSV, setDownloading] = useState(false);

  const downloadFile = (
    dataSource: any[],
    exportFilename?: string,
    columns?: string[]
  ) => {
    let data = Papa.unparse(dataSource, { header: true, columns });
    let csvData = new Blob([data], { type: "text/csv;charset=utf-8;" });
    const msSaveBlob = get(navigator, "msSaveBlob");
    if (msSaveBlob) {
      msSaveBlob(csvData, exportFilename);
    } else {
      // In FF link must be added to DOM to be clicked
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(csvData);
      link.setAttribute("download", exportFilename || "data.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    setDownloading(false);
  };

  return [downloadFile, downloadingCSV] as const;
}
