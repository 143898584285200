// TOP LEVEL IMPORTS
import React from "react";
import styled from "styled-components";
import { LoadingOutlined } from "@ant-design/icons";

const getBackgroundColor = ({
  grey,
  disabled,
  secondary,
  theme,
  yellow,
  danger,
  select,
  buttonComplete,
  gray,
  orange,
  lightblue,
  greyActive,
  green,
}: any) => {
  if (disabled) {
    return theme.colors.neutral8;
  }

  if (lightblue) {
    return theme.colors.blue7;
  }
  if (danger) {
    return theme.colors.red10;
  }
  if (buttonComplete) {
    return theme.colors.buttonComplete;
  }
  if (grey) {
    return theme.colors.neutral10;
  }
  if (gray) {
    return theme.colors.gray1;
  }
  if (secondary) {
    return theme.colors.primary10;
  }
  if (yellow) {
    return theme.colors.yellow10;
  }
  if (select) {
    return theme.colors.buttonSelect;
  }
  if (orange) {
    return theme.colors.primary11;
  }
  if (greyActive) {
    return theme.colors.gray1;
  }
  if (green) {
    return theme.colors.green1;
  }

  return theme.colors.primary1;
};

const getTextColor = ({
  grey,
  danger,
  disabled,
  yellow,
  secondary,
  orange,
  greyActive,
  lightblue,
  green,
  black,
  theme,
}: any) => {
  if (disabled) {
    return theme.colors.neutral7;
  }

  if (lightblue) {
    return theme.colors.white1;
  }
  if (grey) {
    return theme.colors.neutral4;
  }
  if (secondary) {
    return theme.colors.primary1;
  }
  if (yellow) {
    return theme.colors.yellow1;
  }
  if (danger) {
    return theme.colors.red1;
  }
  if (orange) {
    return theme.colors.white1;
  }
  if (greyActive) {
    return theme.colors.white1;
  }
  if (green) {
    return theme.colors.white1;
  }
  if (black) {
    return theme.colors.black;
  }

  return "#fff";
};

const getHoverBackgroundColor = ({
  grey,
  disabled,
  secondary,
  theme,
  danger,
  yellow,
  orange,
  greyActive,
  lightblue,
  green,
}: any) => {
  if (disabled) {
    return theme.colors.neutral8;
  }
  if (lightblue) {
    return theme.colors.blue8;
  }
  if (danger) {
    return theme.colors.red9;
  }
  if (grey) {
    return theme.colors.neutral9;
  }
  if (secondary) {
    return theme.colors.primary9;
  }
  if (yellow) {
    return theme.colors.yellow9;
  }
  if (orange) {
    return theme.colors.primary11;
  }
  if (greyActive) {
    return theme.colors.gray1;
  }
  if (green) {
    return theme.colors.green1;
  }

  return theme.colors.primary2;
};

const PrimaryButtonContainer = styled.button<{
  fullWidth?: boolean;
  style?: React.CSSProperties;
  disabled?: boolean;
  secondary?: boolean;
  danger?: boolean;
  grey?: boolean;
  lightblue?: boolean;
  buttonComplete?: boolean;
  gray?: boolean;
  yellow?: boolean;
  select?: boolean;
  orange?: boolean;
  greyActive?: boolean;
  green?: boolean;
  theme?: any;
}>`
  width: ${({ fullWidth, style }) => {
    if (fullWidth) return "100%";
    if (style?.width) return `${style?.width}px`;
  }};
  height: 26px;
  border-radius: 4px;
  background-color: ${(props) => getBackgroundColor(props)};
  border: 0px;
  padding-left: 16px;
  padding-right: 16px;
  line-height: 40px;
  letter-spacing: 0.025em;
  align-items: center;
  transition: color 0.3s ease, background-color 0.3s ease,
    border-color 0.3s ease, width 0.3s ease, opacity 0.3s ease;
  :hover {
    cursor: ${(p) => {
      if (p.disabled) {
        return "not-allowed";
      }
      return "pointer";
    }};
    background-color: ${(props) => getHoverBackgroundColor(props)};
  }
  :focus {
    outline: 0;
  }
  &:active {
    transform: translatey(1px);
  }
`;

const ButtonText = styled.div<{
  disabled?: boolean;
  secondary?: boolean;
  lightblue?: boolean;
  danger?: boolean;
  grey?: boolean;
  yellow?: boolean;
  orange?: boolean;
  greyActive?: boolean;
  green?: boolean;
  theme?: any;
  black?: boolean;
  smallSize?: boolean;
}>`
  font-family: ${(p) => p.theme.fontFamily};
  font-size: ${(props) => (props.smallSize ? "12px" : "14px")};
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.8px;
  text-align: center;
  color: ${(props) => getTextColor(props)};
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface PrimaryButtonProps {
  fullWidth?: boolean;
  style?: React.CSSProperties;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  ["aria-label"]?: string;
  secondary?: boolean;
  danger?: boolean;
  grey?: boolean;
  gray?: boolean;
  lightblue?: boolean;
  buttonComplete?: boolean;
  yellow?: boolean;
  orange?: boolean;
  greyActive?: boolean;
  green?: boolean;
  loading?: boolean;
  select?: boolean;
  black?: boolean;
  children?: any;
  type?: any;
  smallSize?: boolean;
}

const PrimaryButton = (props: PrimaryButtonProps) => (
  <PrimaryButtonContainer
    style={props.style}
    disabled={props.disabled}
    fullWidth={props.fullWidth}
    onClick={props.onClick}
    select={props.select}
    secondary={props.secondary}
    danger={props.danger}
    buttonComplete={props.buttonComplete}
    lightblue={props.lightblue}
    grey={props.grey}
    gray={props.gray}
    yellow={props.yellow}
    orange={props.orange}
    greyActive={props.greyActive}
    green={props.green}
    aria-label={props["aria-label"] || "Button"}
    type={props.type}
  >
    <ButtonText
      disabled={props.disabled}
      secondary={props.secondary}
      danger={props.danger}
      grey={props.grey}
      lightblue={props.lightblue}
      yellow={props.yellow}
      orange={props.orange}
      greyActive={props.greyActive}
      green={props.green}
      black={props.black}
      smallSize={props.smallSize}
    >
      {!props.loading ? props.children : <LoadingOutlined />}
    </ButtonText>
  </PrimaryButtonContainer>
);

export default PrimaryButton;
