import styled from "styled-components";
import PageTitle from "components/text/PageTitle";
import Caption from "components/text/Caption";
import logoPNG from "lib/media/logo.png";

const Container = styled.div`
  min-height: 200px;
  width: 550px;
  margin: auto;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

interface ErrorStateProps {
  title?: string;
  subtitle?: string;
  image?: any;
}

export default function ErrorState({
  title,
  subtitle,
  image,
}: ErrorStateProps) {
  return (
    <Container>
      <div>
        {image ? (
          image
        ) : (
          <img
            src={logoPNG}
            alt="apiphani-logo"
            height="80"
            style={{ marginBottom: 24 }}
          />
        )}
        <PageTitle style={{ marginBottom: 8 }}>{title || "Error..."}</PageTitle>
        {subtitle && <Caption>{subtitle || "Error..."}</Caption>}
      </div>
    </Container>
  );
}
