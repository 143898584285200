import styled from "styled-components";
import { useForm } from "react-hook-form";
// COMPONENTS
import FormItem from "components/common/FormItem";
import TextInput from "components/inputs/TextInput";
import Button from "components/common/Button";

const Form = styled.form`
  width: 550px;
  max-width: 100%;
`;

interface OrganiaztionFormProps {
  onSubmit: any;
  onCancel: any;
  loading?: boolean;
  defaultValues?: {
    name?: string | null;
  };
}

export default function OrganizationForm({
  onSubmit,
  onCancel,
  loading,
  defaultValues,
}: OrganiaztionFormProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: defaultValues?.name,
    },
  });

  const beforeOnSubmit = handleSubmit((data) => {
    if (!data) return;
    onSubmit(data);
  });

  return (
    <Form onSubmit={beforeOnSubmit}>
      <FormItem label="Client Name" required error={errors?.name?.message}>
        <TextInput
          {...register("name", {
            required: "Name is required.",
          })}
        />
      </FormItem>
      <FormItem>
        <Button
          type="button"
          onClick={onCancel}
          grey
          style={{ width: 100, marginRight: 16 }}
        >
          Cancel
        </Button>
        <Button
          style={{ width: 100 }}
          type="submit"
          loading={loading}
          disabled={loading}
        >
          Submit
        </Button>
      </FormItem>
    </Form>
  );
}
