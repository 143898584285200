import styled from "styled-components";

export default styled.div`
  position: relative;
  .ant-select,
  .ant-select-multiple,
  .ant-select-selector {
    min-height: ${(p) => p.style?.minHeight || "54px"};
    background: ${(p) => p.theme.colors.neutral11};
    border: 1px solid ${(p) => p.theme.colors.neutral9} !important;
    border-radius: 5px;
  }

  .ant-select-selection-item {
    font-weight: 400 !important;
    color: ${(p) => p.theme.colors.neutral3} !important;
  }
  .ant-select-multiple {
    .ant-select-selection-item {
      background: #fff;
    }
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: ${(p) => p.style?.height || "50px"};
    align-items: center;
    display: flex;
    border-radius: 5px;
    border: 1px solid ${(p) => p.theme.colors.neutral9};
    background: ${(p) => p.theme.colors.neutral11};
  }
  .ant-select-selection-search-input {
    height: ${(p) => p.style?.height || "48px"};
  }
  .ant-select-selection-placeholder {
    color: ${(p) => p.theme.colors.neutral7};
    opacity: 1;
  }
`;
