import styled from "styled-components";
import { useForm, Controller } from "react-hook-form";
// COMPONENTS
import FormItem from "components/common/FormItem";
import TextInput from "components/inputs/TextInput";
import Button from "components/common/Button";
import { toString } from "lodash";
import SitesMultiSelectInput from "components/inputs/SitesMultiSelectInput";

const Form = styled.form`
  width: 550px;
  max-width: 100%;
`;

const Gap = styled.div`
  margin-top: 12px;
`;

interface StudyFormProps {
  onSubmit: any;
  onCancel: any;
  loading?: boolean;
  organizationId?: string;
  defaultValues?: {
    name?: string | null;
    siteIds?: string[] | null;
  };
}

export default function StudyForm({
  onSubmit,
  onCancel,
  loading,
  defaultValues,
  organizationId,
}: StudyFormProps) {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: defaultValues?.name,
      siteIds: defaultValues?.siteIds || [],
    },
  });

  const beforeOnSubmit = handleSubmit((data) => {
    console.log(data);
    if (!data) return;
    onSubmit(data);
  });

  return (
    <Form onSubmit={beforeOnSubmit}>
      <FormItem label="Study Name" required error={errors?.name?.message}>
        <TextInput
          {...register("name", {
            required: "Name is required.",
          })}
        />
      </FormItem>
      <Gap />
      <FormItem
        label="Sites"
        error={errors?.siteIds ? "This is required" : undefined}
      >
        <Controller
          name="siteIds"
          control={control}
          render={({ field }) => {
            const { ref: innerRef, ...restField } = field;
            return (
              <SitesMultiSelectInput
                {...restField}
                innerRef={innerRef}
                value={field?.value as string[]}
                organizationIds={
                  organizationId ? [toString(organizationId)] : []
                }
                onChange={(sites: any) => {
                  field.onChange(sites);
                }}
              />
            );
          }}
        />
      </FormItem>
      <Gap />
      <FormItem>
        <Button
          type="button"
          onClick={onCancel}
          grey
          style={{ width: 100, marginRight: 16 }}
        >
          Cancel
        </Button>
        <Button
          style={{ width: 100 }}
          type="submit"
          loading={loading}
          disabled={loading}
        >
          Submit
        </Button>
      </FormItem>
    </Form>
  );
}
