import TruncateMarkup from "react-truncate-markup";
import Tooltip from "components/common/Tooltip";
import React from "react";

export default function TruncateText({
  text,
  children,
  style,
}: {
  text: any;
  children: any;
  style?: React.CSSProperties;
}) {
  return (
    <Tooltip title={text}>
      <div style={{ display: "inline", ...style }}>
        <TruncateMarkup lines={1}>{children}</TruncateMarkup>
      </div>
    </Tooltip>
  );
}
