import AntdDrawer, { DrawerProps } from "antd/lib/drawer";
import "antd/lib/drawer/style/css";

export default function Drawer(props: DrawerProps | any) {
  return (
    <AntdDrawer
      placement="right"
      width={600}
      height={200}
      destroyOnClose
      getContainer={false}
      style={{ position: "fixed", top: 0, bottom: 0, overflowY: "hidden" }}
      {...props}
    >
      {props?.children}
    </AntdDrawer>
  );
}
