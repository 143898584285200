import CompleteBlue from "components/common/Icons/Progress/Pie/CompleteBlue";
import HalfYellow from "components/common/Icons/Progress/Pie/HalfYellow";
import NoneGray from "components/common/Icons/Progress/Pie/NoneGray";
import QuarterOrange from "components/common/Icons/Progress/Pie/QuarterOrange";
import ThreeQuarterGreen from "components/common/Icons/Progress/Pie/ThreeQuarterGreen";
import { SubjectTotalStatusEnum } from "lib/helpers/analysisStatusHashMap";

export const IconByStatus = {
  [SubjectTotalStatusEnum.Available]: NoneGray,
  [SubjectTotalStatusEnum.AnalysisPending]: QuarterOrange,
  [SubjectTotalStatusEnum.AnalysisInitiated]: HalfYellow,
  [SubjectTotalStatusEnum.UnderReview]: ThreeQuarterGreen,
  [SubjectTotalStatusEnum.Reviewed]: CompleteBlue,
  [SubjectTotalStatusEnum.Completed]: CompleteBlue,
};
