import styled from "styled-components";
import { ReactChildren, ReactChild } from "react";

// STYLE-COMPONENTS
// ===================================
const Container = styled.div`
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.background};
`;

// EXPORT
// ===================================
export default function PublicLayout({
  children,
  style,
}: {
  children: ReactChild | ReactChildren;
  style?: React.CSSProperties;
}) {
  return (
    <Container id="public-layout" style={style}>
      {children}
    </Container>
  );
}
