import styled from "styled-components";

export default styled.div`
  border: 2px solid ${({ theme }) => theme.colors.neutral9};
  background-color: ${({ theme }) => theme.colors.neutral10};
  padding: 16px;
  border-radius: 5px;
  min-height: 170px;
  background: #fff;
  width: 100%;
`;
