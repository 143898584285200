import {
  AlertOutlined,
  LoadingOutlined,
  StockOutlined,
} from "@ant-design/icons";
import Modal from "components/common/Modal";
import styled from "styled-components";
// HOOKS
import ModalTitle from "components/text/ModalTitle";

const DownloadOption = styled.button`
  border: 2px solid ${({ theme }) => theme.colors.neutral8};
  border-radius: 5px;
  padding: 16px 24px;
  color: ${({ theme }) => theme.colors.neutral3};
  background-color: transparent;
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 18px;
  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.neutral10};
  }

  p {
    font-size: 16px;
    font-weight: 500;
    margin: 0px;
    line-height: 18px;
  }

  span {
    text-align: left;
  }

  .anticon {
    color: ${({ theme }) => theme.colors.neutral6};
    font-size: 20px;
    margin-right: 16px;
  }
`;

export enum DownloadTypeEnum {
  "DeviceEvents" = "DeviceEvents",
  "DeviceSessions" = "DeviceSessions",
  "DeviceEventsXPT" = "DeviceEventsXPT",
}

export enum EventDownloadFileType {
  "XPT" = "XPT",
  "CSV" = "CSV",
}

export default function DownloadOptionsModal({
  visible,
  onDownloadSessions,
  downloading,
  onCancel,
  onDownloadEvents,
}: {
  visible: boolean;
  onDownloadSessions: any;
  onDownloadEvents: (fileType: EventDownloadFileType) => void;
  downloading: DownloadTypeEnum | false;
  onCancel: () => void;
}) {
  return (
    <Modal footer={null} visible={visible} onCancel={onCancel}>
      <ModalTitle>Which data set would you like to download?</ModalTitle>
      <div style={{ padding: 24, paddingTop: 32 }}>
        <DownloadOption
          onClick={onDownloadSessions}
          style={{ marginBottom: 24 }}
          disabled={downloading === DownloadTypeEnum.DeviceSessions}
        >
          {downloading !== DownloadTypeEnum.DeviceSessions ? (
            <>
              <AlertOutlined />
              <span>
                <p>Session Summary (.csv)</p>
              </span>
            </>
          ) : (
            <LoadingOutlined />
          )}
        </DownloadOption>
        <DownloadOption
          style={{ marginBottom: 24 }}
          onClick={() => onDownloadEvents(EventDownloadFileType.CSV)}
          disabled={downloading === DownloadTypeEnum.DeviceEvents}
        >
          {downloading !== DownloadTypeEnum.DeviceEvents ? (
            <>
              <StockOutlined />
              <span>
                <p>Session Events (.csv)</p>
              </span>
            </>
          ) : (
            <LoadingOutlined />
          )}
        </DownloadOption>
        <DownloadOption
          onClick={() => onDownloadEvents(EventDownloadFileType.XPT)}
          disabled={downloading === DownloadTypeEnum.DeviceEventsXPT}
        >
          {downloading !== DownloadTypeEnum.DeviceEventsXPT ? (
            <>
              <StockOutlined />
              <span>
                <p>Session Events (.xpt)</p>
              </span>
            </>
          ) : (
            <LoadingOutlined />
          )}
        </DownloadOption>
      </div>
    </Modal>
  );
}
