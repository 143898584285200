import React from "react";

const baseValidKeys = [
  "Tab",
  "Home",
  "End",
  "Meta",
  "Backspace",
  "Delete",
  "ArrowLeft",
  "ArrowRight",
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
];

export const onNegativeNumberFieldKeydown = (
  e: React.KeyboardEvent<HTMLInputElement>
) => {
  const { key, currentTarget } = e;

  const { value, selectionStart, selectionEnd } = currentTarget;

  if (typeof selectionStart === "number" && selectionStart === 0) {
    if (key === "-" && selectionEnd === 0 && value.startsWith("-")) {
      e.preventDefault();
      e.stopPropagation();
    } else if (!["-", ...baseValidKeys].includes(key)) {
      e.preventDefault();
      e.stopPropagation();
    }
  } else if (
    typeof selectionStart === "number" &&
    selectionStart !== 0 &&
    !baseValidKeys.includes(key)
  ) {
    e.preventDefault();
    e.stopPropagation();
  }
};

export const onNumberFieldKeydown = (
  e: React.KeyboardEvent<HTMLInputElement>
) => {
  if (!baseValidKeys.includes(e.key)) {
    e.preventDefault();
    e.stopPropagation();
  }
};
