import { useState } from "react";
import {
  DeviceSession,
  GetManyDeviceSessionsDocument,
  GetManyDeviceSessionsQuery,
  GetManyDeviceSessionsQueryVariables,
  GetOtherDataDocument,
  GetOtherDataQuery,
  OtherDataResponse,
  OtherDataUnitEnum,
} from "generated/graphql";
import Papa from "papaparse";
import numeral from "numeral";
import moment from "moment";
import client from "ApolloClient/index";
import useDownloadZip from "lib/hooks/useDownloadZip";

import { ApolloQueryResult } from "@apollo/client";
import { DeepPartial } from "utility-types";

import message from "components/common/message";
import { omit, toString } from "lodash";

import { DownloadTypeEnum } from "./DownloadOptionsModal";
import { deviceStatusMap } from "lib/helpers/deviceStatusMap";

const cleanLabel = (label: string) =>
  label
    ?.replace("_", " ")
    .replace(/(^|\s)[A-Za-zÀ-ÖØ-öø-ÿ]/g, (c) => c.toUpperCase());

const cleanDataForCSV = (
  sessions: DeepPartial<DeviceSession>[] | [],
  fieldsToRemove?: string[]
) => {
  return sessions.map((session) => {
    const row: any = {
      "Session ID": session.sessionId,
      "Device ID": session.deviceId,
      Status: Object.keys(deviceStatusMap)?.find(
        (key) => key === session?.status?.toString()
      ),
      Client: session?.organization?.name,
      "Start - 24h": session?.firstDay,
      "End - 24h": session?.lastDay,
      Change: session.change,
    };
    session?.dailyEvents?.forEach((event: any, index: any) => {
      row[`Day ${index + 1}`] = event?.value;
    });
    return omit(row, fieldsToRemove || []);
  });
};

// interface RawOtherDataForCSV {
//   "Session ID": string;
//   "Device ID"?: string;
//   "Start - 24": string;
//   "End - 24": string;
//   Change: string;
//   "Day 1"?: string;
//   "Day 2"?: string;
//   "Day 3"?: string;
//   "Day 4"?: string;
//   "Day 5"?: string;
//   "Day 6"?: string;
//   "Day 7"?: string;
// }

const formatOtherData = (
  dataPoint: OtherDataResponse,
  fieldsToRemove?: string[]
): any => {
  const dayData: any = {};

  dataPoint?.dailyEvents?.forEach((event) => {
    dayData[event?.day as "Day 1"] = event?.value;
  });

  return omit(
    {
      "Session ID": toString(dataPoint?.sessionId),
      "Device ID": toString(dataPoint?.deviceId),
      "Start - 24": toString(dataPoint?.firstDay),
      "End - 24": toString(dataPoint?.lastDay),
      Change: toString(numeral(dataPoint?.change)?.format("0.0")),
      ...dayData,
    },
    fieldsToRemove || []
  );
};

export default function useOnDownloadSessionsSummary() {
  const [downloadZip] = useDownloadZip();

  const [downloading, setDownloading] = useState<DownloadTypeEnum | false>(
    false
  );

  const onDownloadSessions = async (
    variables: any,
    fieldsToRemove?: string[]
  ) => {
    try {
      setDownloading(DownloadTypeEnum.DeviceSessions);

      const dataSources: any[] = [];

      const vars: GetManyDeviceSessionsQueryVariables = {
        ...variables,
        pagination: {
          skip: 0,
          limit: 1000,
        },
      };

      const result: ApolloQueryResult<GetManyDeviceSessionsQuery> =
        await client.query({
          query: GetManyDeviceSessionsDocument,
          variables: vars,
        });

      dataSources.push({
        name: `24hrCoughs-${moment()?.format("MMM DD YYYY")}.csv`,
        data: cleanDataForCSV(
          result?.data?.getManyDeviceSessions?.results || [],
          fieldsToRemove
        ),
      });

      // get steps

      const stepsResult: ApolloQueryResult<GetOtherDataQuery> =
        await client.query({
          query: GetOtherDataDocument,
          variables: {
            unit: OtherDataUnitEnum.Steps,
          },
        });

      dataSources.push({
        name: `Steps-${moment()?.format("MMM DD YYYY")}.csv`,
        data: stepsResult?.data?.getOtherData?.map((item) =>
          formatOtherData(item, fieldsToRemove)
        ),
      });

      const respitoryResult: ApolloQueryResult<GetOtherDataQuery> =
        await client.query({
          query: GetOtherDataDocument,
          variables: {
            unit: OtherDataUnitEnum.MedRespRate,
          },
        });

      dataSources.push({
        name: `RespitoryRates-${moment()?.format("MMM DD YYYY")}.csv`,
        data: respitoryResult?.data?.getOtherData?.map((item) =>
          formatOtherData(item, fieldsToRemove)
        ),
      });

      const awakeCoughsResults: ApolloQueryResult<GetOtherDataQuery> =
        await client.query({
          query: GetOtherDataDocument,
          variables: {
            unit: OtherDataUnitEnum.Coughs,
          },
        });

      dataSources.push({
        name: `AwakeCoughs-${moment()?.format("MMM DD YYYY")}.csv`,
        data: awakeCoughsResults?.data?.getOtherData?.map((item) =>
          formatOtherData(item, fieldsToRemove)
        ),
      });

      const files = dataSources?.map((dataSource) => {
        let data = Papa.unparse(dataSource.data, {
          header: true,
        });
        let csvData = new Blob([data], { type: "text/csv;charset=utf-8;" });
        return {
          name: dataSource.name,
          file: csvData,
        };
      });

      await downloadZip(files, "SessionSummaryData.zip");

      setDownloading(false);
    } catch (err: any) {
      setDownloading(false);
      message.error(err.message);
      console.log(err);
    }
  };

  return [onDownloadSessions, downloading] as const;
}
