import Select from "antd/lib/select";
import "antd/lib/select/style/css";
import { Maybe, DeviceSessionIssueTypeEnum } from "generated/graphql";
import DropdownStyleWrapper from "../DropdownStyleWrapper";
import sessionsIssueHashMap from "lib/helpers/sessionsIssueHashMap";
// CONSTANTS AND DESTRUCTURING
// ================================================================
const Option = Select.Option;

// EXPORTED COMPONENT
// ================================================================
const IssueTypeSelectInput = (props: {
  onChange: (newValue: DeviceSessionIssueTypeEnum) => void;
  value?: Maybe<DeviceSessionIssueTypeEnum>;
  style?: React.CSSProperties;
}) => {
  const { value, style } = props;

  return (
    <DropdownStyleWrapper>
      <Select
        {...props}
        value={value || undefined}
        showArrow={false}
        filterOption={false}
        optionFilterProp="children"
        showSearch
        style={{
          width: "100%",
          margin: "auto",
          ...style,
        }}
      >
        {Object.values(DeviceSessionIssueTypeEnum)?.map((key) => {
          return (
            <Option key={key} value={key}>
              {sessionsIssueHashMap[key]}
            </Option>
          );
        })}
      </Select>
    </DropdownStyleWrapper>
  );
};

// EXPORT
// ================================================================
export default IssueTypeSelectInput;
