import { UserTypeEnum } from "generated/graphql";
import { Route, Redirect } from "react-router-dom";

// EXPORTED COMPONENT
// ==============================
const ClientRoute = (props: any) => {
  const {
    currentUser,
    showHeader,
    showFooter,
    component: Component,
    path,
    backgroundColor,
    layout: Layout,
    ...rest
  } = props;

  if (
    !currentUser ||
    (currentUser?.userType !== UserTypeEnum.Client &&
      currentUser?.userType !== UserTypeEnum.SuperAdmin)
  ) {
    return <Redirect to="/login" />;
  }

  return (
    <Route
      {...rest}
      path={path}
      render={(args) => (
        <Layout
          currentUser={currentUser}
          backgroundColor={backgroundColor}
          showHeader={showHeader}
          showFooter={showFooter}
        >
          <Component currentUser={currentUser} {...args} />
        </Layout>
      )}
    />
  );
};

// EXPORT
// ==============================
export default ClientRoute;
