import {
  ApolloClient,
  HttpLink,
  ApolloLink,
  InMemoryCache,
} from "@apollo/client";
import authLink from "./links/authLink";
import apolloLogger from "apollo-link-logger";
import config, { NodeEnvironments } from "lib/config";

const httpLink = new HttpLink({ uri: config.GRAPHQL_API });
// for some reason, need to pass uri in via links like https://github.com/apollographql/apollo-link/issues/1246#issuecomment-656092271
let links = [authLink, httpLink];

if (config.NODE_ENV === NodeEnvironments.development) {
  links = [authLink, apolloLogger, httpLink];
}

const client = new ApolloClient({
  name: "react-web-client",
  cache: new InMemoryCache(),
  link: ApolloLink.from(links),
});

export default client;
