import Select from "antd/lib/select";
import "antd/lib/select/style/css";
import { useFindStudiesQuery } from "generated/graphql";
import { useEffect, useMemo, useState } from "react";
import DropdownStyleWrapper from "components/inputs/DropdownStyleWrapper";
import { RefCallBack } from "react-hook-form";

// CONSTANTS AND DESTRUCTURING
// ================================================================
const Option = Select.Option;

interface Props {
  onChange: any;
  value: string[];
  organizationIds: string[];
  style?: React.CSSProperties;
  active?: boolean;
  placeholder?: string;
  innerRef?: RefCallBack;
}

// EXPORTED COMPONENT
// ================================================================
const TrialsMultiSelect = ({
  organizationIds,
  active,
  style,
  innerRef,
  ...props
}: Props) => {
  const queryOptions = useMemo(() => {
    if (!organizationIds || organizationIds.length === 0) {
      return {};
    }

    return {
      variables: {
        clientId: organizationIds[0],
      },
    };
  }, [organizationIds]);

  const { data, loading } = useFindStudiesQuery(queryOptions);
  const { onChange, placeholder, value = [], ...restProps } = props;
  const displayable = value.filter(
    (id) =>
      data?.findStudies?.items?.find((study) => study?.id === id) !== undefined
  );

  let dataFilterable = data?.findStudies?.items
    ?.map((value) => value)
    ?.sort((a, b) => {
      if (a?.name && b?.name && a?.name > b?.name) {
        return 1;
      }
      if (a?.name && b?.name && b?.name > a?.name) {
        return -1;
      }
      return 0;
    });

  return (
    <DropdownStyleWrapper style={style}>
      <Select
        {...restProps}
        ref={innerRef}
        size="large"
        onChange={(e) => onChange(e)}
        optionFilterProp="children"
        placeholder={placeholder || "Studies"}
        loading={loading}
        value={displayable}
        mode="tags"
        style={{
          width: "100%",
          margin: "auto",
          ...style,
        }}
        maxTagCount="responsive"
      >
        {!loading &&
          dataFilterable
            ?.sort((a, b) => {
              const filtered1 = value.findIndex((entry) => entry === a?.id);
              const filtered2 = value.findIndex((entry) => entry === b?.id);
              if (filtered1 < 0 && filtered2 < 0) {
                return 0;
              } else if (filtered1 < 0) {
                return 1;
              } else if (filtered2 < 0) {
                return -1;
              }
              return 1;
            })
            ?.map((trial) => {
              return (
                <Option key={trial?.id} value={trial?.id}>
                  {trial?.name}
                </Option>
              );
            })}
      </Select>
    </DropdownStyleWrapper>
  );
};

// EXPORT
// ================================================================
export default TrialsMultiSelect;
