import SitesMultiSelectInput from "components/inputs/SitesMultiSelectInput";
import TrialsMultiSelect from "components/inputs/TrialsMultiSelect";
import OrganizationsMultiSelectInput from "components/inputs/OrganizationsMultiSelectInput";
import useUrlChange from "lib/hooks/useUrlChange";
import PageSubtitle from "components/text/PageSubtitle";
import Button from "components/common/Button";
import Row from "components/common/Row";
import Col from "components/common/Col";
import SwitchInput from "components/inputs/SwitchInput";
import styled from "styled-components";

const Section = styled.section`
  .ant-row-flex,
  .ant-row {
    margin: 0 !important;
  }
  box-sizing: border-box !important;
`;

export default function FiltersRow({
  onFiltersChange,
}: {
  onFiltersChange: (values?: any) => void;
}) {
  const {
    query: { organizationIds, siteIds, trialIds, active },
    onUrlChange,
  } = useUrlChange();
  return (
    <div style={{ marginTop: 32, marginBottom: 24 }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "98%",
        }}
      >
        <PageSubtitle style={{ marginLeft: 10 }}>Filters</PageSubtitle>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <label style={{ marginRight: 8 }}>Show Only Active: </label>
          <SwitchInput
            checked={active?.toString()?.includes("true") ? true : undefined}
            onChange={(newActive) =>
              onUrlChange({
                active: newActive?.toString()?.includes("true") ? true : false,
                trialIds: undefined,
                organizationIds: undefined,
                siteIds: undefined,
              })
            }
          />
        </div>
      </div>
      <Section>
        <Row style={{ marginBottom: 16 }} gutter={16}>
          <Col xs={8}>
            <OrganizationsMultiSelectInput
              onChange={(organizationIds: string[]) =>
                onFiltersChange({
                  organizationIds,
                  trialIds: undefined,
                  siteIds: undefined,
                })
              }
              value={organizationIds as string[]}
              placeholder="Clients"
            />
          </Col>
          <Col xs={8}>
            <TrialsMultiSelect
              onChange={(trialIds: string[]) =>
                onFiltersChange({ trialIds, siteIds: undefined })
              }
              value={trialIds as string[]}
              organizationIds={organizationIds as string[]}
              active={
                Array.isArray(active)
                  ? !!active.find((x) => x === "true")
                  : active === "true"
              }
            />
          </Col>
          <Col xs={8}>
            <SitesMultiSelectInput
              onChange={(siteIds: string[]) => onFiltersChange({ siteIds })}
              value={siteIds as string[]}
              trialIds={trialIds as string[]}
              organizationIds={organizationIds as string[]}
            />
          </Col>
        </Row>
      </Section>
      {(organizationIds || siteIds || trialIds) && (
        <Button
          secondary
          style={{ marginTop: 16, marginLeft: 8 }}
          onClick={() =>
            onFiltersChange({
              organizationIds: undefined,
              siteIds: undefined,
              trialIds: undefined,
            })
          }
        >
          Clear all
        </Button>
      )}
    </div>
  );
}
