import styled from "styled-components";
// COMPONENTS
import FormItem from "components/common/FormItem";
import TextInput from "components/inputs/TextInput";
import Button from "components/common/Button";
import StudyMultiSelectInput from "components/inputs/StudyMultiSelectInput";
import { Site, Study, useFindStudiesQuery } from "generated/graphql";
import React, { useCallback, useEffect, useMemo, useState } from "react";

const Form = styled.form`
  width: 550px;
  max-width: 100%;
`;

interface SiteFormProps {
  onSubmit: any;
  onCancel: any;
  loading?: boolean;
  editData?: Site;
  edit?: boolean;
  organizationId?: string;
  defaultValues?: {
    name?: string | null;
    contactName?: string | null;
    phone?: string | null;
    email?: string | null;
    streetAddress1?: string | null;
    streetAddress2?: string | null;
    city?: string | null;
    state?: string | null;
    zipCode?: string | null;
    country?: string | null;
    studies?: {
      id?: string | null;
      name?: string | null;
    };
  };
}

export default function SiteForm({
  onSubmit,
  onCancel,
  loading,
  defaultValues,
  editData,
  organizationId,
  edit,
}: SiteFormProps) {
  const [name, setName] = useState<string>("");
  const [contactName, setContactName] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [streetAddress1, setStreetAddress1] = useState<string>("");
  const [streetAddress2, setStreetAddress2] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [zipCode, setZipCode] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [country, setCountry] = useState<string>("");
  const [studies, setStudies] = useState<Study[]>([]);
  const { data } = useFindStudiesQuery({
    variables: {
      clientId: organizationId,
    },
    fetchPolicy: "cache-and-network",
  });
  const availableStudies = useMemo(() => {
    if (!data) return {};
    return data.findStudies.items.reduce((acc, study) => {
      if (study) {
        return { ...acc, [study.id]: study };
      }

      return acc;
    }, {} as Record<string, Study>);
  }, [data]);
  const onStudiesChange = useCallback(
    (studies: string | string[]) => {
      if (!studies) {
        setStudies([]);
      } else if (Array.isArray(studies)) {
        const nextStudies = studies.map((studyId) => availableStudies[studyId]);
        setStudies(nextStudies);
      } else {
        setStudies([availableStudies[studies]]);
      }
    },
    [availableStudies]
  );
  const handleSubmit = useCallback(
    (evt: React.FormEvent<HTMLElement>) => {
      evt.preventDefault();
      const stds = studies.map((study) => study.id);
      onSubmit({
        name,
        contactName,
        phone,
        email,
        streetAddress1,
        streetAddress2,
        city,
        state,
        zipCode,
        country,
        studies: stds,
      });
    },
    [
      name,
      contactName,
      phone,
      email,
      streetAddress1,
      streetAddress2,
      city,
      zipCode,
      state,
      country,
      studies,
      onSubmit,
    ]
  );

  useEffect(() => {
    if (edit) {
      setName(editData?.name as string);
      setContactName(editData?.contactName as string);
      setEmail(editData?.email as string);
      setStudies(editData?.studies as []);
      setPhone(editData?.phone as string);
      setStreetAddress1(editData?.streetAddress1 as string);
      setStreetAddress2(editData?.streetAddress2 as string);
      setCity(editData?.city as string);
      setZipCode(editData?.zipCode as string);
      setState(editData?.state as string);
      setCountry(editData?.country as string);
      setStudies(editData?.studies as Study[]);
    }
  }, [edit, editData]);

  return (
    <Form onSubmit={handleSubmit}>
      <FormItem label="Site Name" required>
        <TextInput
          value={name as string}
          onChange={(e) => setName(e.target.value)}
        />
      </FormItem>
      <FormItem label="Contact Name">
        <TextInput
          value={contactName as string}
          onChange={(e) => setContactName(e.target.value)}
        />
      </FormItem>{" "}
      <FormItem label="Phone">
        <TextInput
          value={phone as string}
          onChange={(e) => setPhone(e.target.value)}
        />
      </FormItem>{" "}
      <FormItem label="Email">
        <TextInput
          value={email as string}
          onChange={(e) => setEmail(e.target.value)}
        />
      </FormItem>{" "}
      <FormItem label="Street Address 2">
        <TextInput
          value={streetAddress1 as string}
          onChange={(e) => setStreetAddress1(e.target.value)}
        />
      </FormItem>{" "}
      <FormItem label="Street Address 2">
        <TextInput
          value={streetAddress2 as string}
          onChange={(e) => setStreetAddress2(e.target.value)}
        />
      </FormItem>{" "}
      <FormItem label="City">
        <TextInput
          value={city as string}
          onChange={(e) => setCity(e.target.value)}
        />
      </FormItem>{" "}
      <FormItem label="State">
        <TextInput
          value={state as string}
          onChange={(e) => setState(e.target.value)}
        />
      </FormItem>{" "}
      <FormItem label="Zip Code">
        <TextInput
          value={zipCode as string}
          onChange={(e) => setZipCode(e.target.value)}
        />
      </FormItem>{" "}
      <FormItem label="Country">
        <TextInput
          value={country as string}
          onChange={(e) => setCountry(e.target.value)}
        />
      </FormItem>
      <FormItem label="Study" required>
        <StudyMultiSelectInput
          edit={edit}
          editData={edit ? studies : []}
          value={studies.map((s) => s.id) ?? []}
          organizationIds={organizationId ? organizationId : ""}
          onChange={(changedStudies: string | string[]) => {
            onStudiesChange(changedStudies);
          }}
        />
      </FormItem>
      <FormItem>
        <Button
          type="button"
          onClick={onCancel}
          grey
          style={{ width: 100, marginRight: 16 }}
        >
          Cancel
        </Button>
        <Button
          style={{ width: 100 }}
          type="submit"
          loading={loading}
          disabled={loading}
        >
          {edit ? "Update" : "Submit"}
        </Button>
      </FormItem>
    </Form>
  );
}
