import Select from "antd/lib/select";
import "antd/lib/select/style/css";
import {
  Trial,
  useFindStudiesQuery,
  useGetManySitesQuery,
} from "generated/graphql";
import DropdownStyleWrapper from "../DropdownStyleWrapper";

// CONSTANTS AND DESTRUCTURING
// ================================================================
const Option = Select.Option;

interface Props {
  onChange: any;
  value: string | string[];
  organizationIds: string;
  trialIds?: string | string[];
  style?: React.CSSProperties;
  placeholder?: string;
  editData?: any;
  edit?: boolean;
}

// EXPORTED COMPONENT
// ================================================================
const StudyMultiSelectInput = ({
  organizationIds = "",
  trialIds = [],
  ...props
}: Props) => {
  const { data, loading } = useFindStudiesQuery({
    variables: {
      clientId: organizationIds,
      pagination: {
        limit: 100000,
      },
    },
  });
  const { onChange, placeholder, value = "", edit, editData } = props;

  return (
    <DropdownStyleWrapper
      style={{
        ...props?.style,
      }}
    >
      <Select
        {...props}
        size="large"
        onChange={onChange}
        loading={loading}
        value={
          !loading && edit ? editData.map((item: Trial) => item.id) : value
        }
        optionFilterProp="children"
        placeholder={placeholder || "Select study"}
        mode="tags"
        style={{
          width: "100%",
          margin: "auto",
          ...props?.style,
        }}
        placement="topLeft"
      >
        {!loading &&
          data?.findStudies?.items.map((studies) => {
            if (!studies?.id) return null;
            return (
              <Option key={studies?.id} value={studies?.id}>
                {studies?.name}
              </Option>
            );
          })}
      </Select>
    </DropdownStyleWrapper>
  );
};

// EXPORT
// ================================================================
export default StudyMultiSelectInput;
