// TOP LEVEL IMPORTS
import React from "react";
import { LoadingOutlined } from "@ant-design/icons";

export default function Loading({
  color,
  height = 500,
}: {
  color?: string;
  height?: number;
}) {
  return (
    <div
      style={{
        height,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: color || "#000",
      }}
    >
      <LoadingOutlined />
    </div>
  );
}
