import Select from "antd/lib/select";
import "antd/lib/select/style/css";
import { useGetManySitesQuery } from "generated/graphql";
import { RefCallBack } from "react-hook-form";
import DropdownStyleWrapper from "../DropdownStyleWrapper";

// CONSTANTS AND DESTRUCTURING
// ================================================================
const Option = Select.Option;

interface Props {
  onChange: any;
  value: string[];
  organizationIds: string | string[];
  trialIds?: string | string[];
  style?: React.CSSProperties;
  placeholder?: string;
  innerRef?: RefCallBack;
}

// EXPORTED COMPONENT
// ================================================================
const SitesMultiSelectInput = ({
  organizationIds = [],
  trialIds = [],
  innerRef,
  ...props
}: Props) => {
  const { data, loading } = useGetManySitesQuery({
    variables: {
      organizationIds: organizationIds,
      trialIds: trialIds,
    },
    fetchPolicy: "cache-and-network",
  });
  const { onChange, placeholder, value = [] } = props;
  let dataFilterable = data?.getManySites
    ?.map((value) => value)
    ?.sort((a, b) => {
      if (a?.name && b?.name && a?.name > b?.name) {
        return 1;
      }
      if (a?.name && b?.name && b?.name > a?.name) {
        return -1;
      }
      return 0;
    });
  return (
    <DropdownStyleWrapper
      style={{
        ...props?.style,
      }}
    >
      <Select
        {...props}
        ref={innerRef}
        size="large"
        onChange={onChange}
        loading={loading}
        value={!loading && value ? value : undefined}
        optionFilterProp="children"
        placeholder={placeholder || "Sites"}
        mode="tags"
        style={{
          width: "100%",
          margin: "auto",
          ...props?.style,
        }}
        maxTagCount="responsive"
      >
        {!loading &&
          dataFilterable
            ?.sort((a, b) => {
              const filtered1 = value.findIndex((entry) => entry === a.id);
              const filtered2 = value.findIndex((entry) => entry === b.id);
              if (filtered1 < 0 && filtered2 < 0) {
                return 0;
              } else if (filtered1 < 0) {
                return 1;
              } else if (filtered2 < 0) {
                return -1;
              }
              return 1;
            })
            ?.map((site) => {
              if (!site?.id) return null;
              return (
                <Option key={site?.id} value={site?.id}>
                  {site?.name}
                </Option>
              );
            })}
      </Select>
    </DropdownStyleWrapper>
  );
};

// EXPORT
// ================================================================
export default SitesMultiSelectInput;
