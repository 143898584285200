import { Col, Divider, Row } from "antd";
import styled from "styled-components";
import { CardRegularText } from "components/tables/AnalysisSessionTable/card-elements";
import theme from "lib/theme";
import { SessionLog } from "generated/graphql";
import moment from "moment";

export const StyledContainer = styled.div`
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  width: 100%;
  margin: auto auto;
  height: 68%;
  max-height: 80%;
  margin-top: 10px;
`;

const StyledSpan = styled.span`
  color: ${theme.colors.gray1};
  font-size: 12px;
`;

const CardRegularTextStyled = styled(CardRegularText)`
  font-size: 14px;
  font-weight: 300;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

interface Props {
  logs: SessionLog[];
  tasks?: number;
  completed?: number;
}

export const SessionLogTable = ({ logs, tasks = 0, completed = 0 }: Props) => {
  return (
    <>
      <StyledContainer style={{ padding: "0px 10px" }}>
        <Row gutter={8}>
          <Col span={10}>
            <CardRegularText>Timestamp</CardRegularText>
          </Col>
          <Col span={7}>
            <CardRegularText>Category</CardRegularText>
          </Col>
          <Col span={7}>
            <CardRegularText>Performed by</CardRegularText>
          </Col>
        </Row>
        {logs.map(({ category, changedById, id, createdAt }) => (
          <Row key={id} gutter={8}>
            <Col span={10}>
              <CardRegularTextStyled>
                {moment(createdAt).format("MM/DD/YY, hh:mm a")}
              </CardRegularTextStyled>
            </Col>
            <Col span={7}>
              <CardRegularTextStyled>{category}</CardRegularTextStyled>
            </Col>
            <Col span={7}>
              <CardRegularTextStyled>{changedById}</CardRegularTextStyled>
            </Col>
          </Row>
        ))}
      </StyledContainer>
      <Divider style={{ height: 3, marginTop: 10, marginBottom: 5 }} />
      <div style={{ width: "90%", margin: "auto auto" }}>
        <div style={{ float: "left", width: "65%", textAlign: "left" }}>
          <StyledSpan>Completed: {completed}</StyledSpan>
        </div>
        <div style={{ float: "left", width: "35%", textAlign: "right" }}>
          <StyledSpan>Total: {tasks}</StyledSpan>
        </div>
      </div>
    </>
  );
};
