import styled from "styled-components";
import logo from "lib/media/logo-horizontal-light.png";
import AvatarDropdown from "components/layouts/ClientLayout/Header/AvatarDropdown";
import {
  useGetLastSubjectCompletedAtQuery,
  useGetOneOrganizationQuery,
  UserProfile,
} from "generated/graphql";
import moment from "moment";
import SitesMultiSelectInput from "components/inputs/SitesMultiSelectInput";
import TrialsMultiSelect from "components/inputs/TrialsMultiSelect";
import useUrlChange from "lib/hooks/useUrlChange";

const Container = styled.div`
  /* border-bottom: 2px solid ${({ theme }) => theme.colors.neutral9}; */
  max-width: 100%;
  background: ${({ theme }) => theme.colors.primary3};
`;

const Content = styled.div`
  max-width: 100%;
  display: flex;
  width: 1150px;
  margin: auto;
  height: 88px;
`;

const Row = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const Col = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const formatReleaseDate = (releaseDate: any) => {
  if (!releaseDate) {
    return "";
  }

  if (releaseDate === "n/a") {
    return releaseDate;
  }

  if (
    typeof releaseDate === "string" &&
    releaseDate.match(/^\d{2}\/\d{2}\/\d{4}$/)
  ) {
    return releaseDate;
  }

  return moment(parseInt(releaseDate))?.format("MM/DD/YY");
};

export default function Header({ currentUser }: { currentUser?: UserProfile }) {
  // const { data, loading } = useGetLastSubjectCompletedAtQuery();
  const { data, loading } = useGetOneOrganizationQuery({
    variables: {
      id: currentUser?.organizationId as string,
    },
  });
  const {
    query: { siteIds, trialIds },
    onUrlChange,
  } = useUrlChange();
  const onFiltersChange = (newFilters: any) => {
    onUrlChange({
      ...newFilters,
      current: 1,
      skip: 0,
    });
  };
  return (
    <Container>
      <Content>
        <Row>
          <img src={logo} alt="logo" height="32" width="auto" />
        </Row>
        <Row style={{ flex: 2, justifyContent: "center" }}>
          <TrialsMultiSelect
            onChange={(trialIds: string[]) =>
              onFiltersChange({ trialIds, siteIds: undefined })
            }
            value={trialIds as string[]}
            organizationIds={[currentUser?.organizationId as string]}
            style={{ width: 220, marginRight: 16, minHeight: 32, height: 42 }}
            active
          />
          <SitesMultiSelectInput
            onChange={(siteIds: string[]) => onFiltersChange({ siteIds })}
            trialIds={trialIds as string[]}
            value={siteIds as string[]}
            organizationIds={[currentUser?.organizationId as string]}
            style={{ width: 220, minHeight: 32, height: 42 }}
          />
        </Row>

        <Row>
          <Col>
            <span
              style={{
                fontSize: "12px",
                fontWeight: 600,
                marginRight: 32,
                color: "#fff",
              }}
            >
              {!loading && data?.getOneOrganization?.name}
            </span>
            <span style={{ fontSize: "12px", marginRight: 32, color: "#fff" }}>
              Last Updated:{" "}
              {!loading && data?.getOneOrganization?.dataUpdatedAt
                ? moment(data?.getOneOrganization?.dataUpdatedAt).format(
                    "MM/DD/YY, hh:mm a"
                  )
                : "N/A"}
            </span>
          </Col>
          <AvatarDropdown currentUser={currentUser} />
        </Row>
      </Content>
    </Container>
  );
}
