import Select from "antd/lib/select";
import "antd/lib/select/style/css";
import { useGetManyOrganizationsQuery } from "generated/graphql";
import DropdownStyleWrapper from "../DropdownStyleWrapper";
import { omit } from "lodash";

// CONSTANTS AND DESTRUCTURING
// ================================================================
const Option = Select.Option;

// EXPORTED COMPONENT
// ================================================================
const OrganizationsMultiSelectInput = (props: {
  onChange: any;
  value: string[];
  style?: React.CSSProperties;
  placeholder?: string;
}) => {
  const { data, loading } = useGetManyOrganizationsQuery({});
  const { onChange, value = [] } = props;
  let dataFilterable = data?.getManyOrganizations?.map((value) => value);
  return (
    <DropdownStyleWrapper>
      <Select
        {...omit(props, ["organizationId"])}
        size="large"
        onChange={onChange}
        value={!loading && value ? value : undefined}
        optionFilterProp="children"
        placeholder={props?.placeholder || "Clients"}
        mode="tags"
        style={{
          width: "100%",
          margin: "auto",
          ...props?.style,
        }}
      >
        {!loading &&
          dataFilterable
            ?.sort((a, b) => {
              const filtered1 = value.findIndex((entry) => entry === a.id);
              const filtered2 = value.findIndex((entry) => entry === b.id);
              if (filtered1 < 0 && filtered2 < 0) {
                return 0;
              } else if (filtered1 < 0) {
                return 1;
              } else if (filtered2 < 0) {
                return -1;
              }
              return 1;
            })
            ?.map((site) => {
              if (!site?.id) return null;
              return (
                <Option key={site?.id} value={site?.id}>
                  {site?.name}
                </Option>
              );
            })}
      </Select>
    </DropdownStyleWrapper>
  );
};

// EXPORT
// ================================================================
export default OrganizationsMultiSelectInput;
