import { gql } from "@apollo/client";

export default gql`
  query GetSubjectReportFiles($subjectId: ID!) {
    getSubjectReportFiles(subjectId: $subjectId) {
      fileName
      content
    }
  }
`;
