import queryString from "query-string";
import { useMemo } from "react";
import { useHistory } from "react-router-dom";

export default function useUrlChange() {
  const history = useHistory();

  const onUrlChange = useMemo(
    () => (newValues: any, baseUrl?: string) => {
      const existing = queryString.parse(window.location.search, {
        arrayFormat: "index",
      });
      const newUrl = {
        ...existing,
        ...newValues,
      };
      const url = baseUrl || window.location.pathname;
      const query = queryString.stringify(newUrl, {
        arrayFormat: "index",
      });
      const newUrlString = `${url}?${query}`;

      history.push(newUrlString);
    },
    [history]
  );

  const search = window.location.search;

  const query = useMemo(
    () =>
      queryString.parse(search, {
        arrayFormat: "index",
      }),
    [search]
  );

  return {
    onUrlChange,
    query,
  };
}
