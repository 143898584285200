import { MinusCircleOutlined } from "@ant-design/icons";
import { DeviceAnnotationStatusEnum } from "generated/graphql";
import IconMenu from "lib/media/iconChartCard";
import theme from "lib/theme";

const iconStyles: any = {
  fontSize: 17,
  position: "relative",
  top: 2,
};

export enum SubjectTotalStatusEnum {
  Available = "Available",
  AnalysisPending = "AnalysisPending",
  AnalysisInitiated = "AnalysisInitiated",
  UnderReview = "UnderReview",
  Reviewed = "Reviewed",
  Completed = "Completed",
}

export const SubjectStatusOrder: Record<string, number> = {
  Available: 0,
  AnalysisPending: 1,
  AnalysisInitiated: 2,
  UnderReview: 3,
  Reviewed: 4,
  Completed: 5,
};

export const actualDeviceStatuses = {
  [SubjectTotalStatusEnum.Available.valueOf()]:
    DeviceAnnotationStatusEnum.Available,
  [SubjectTotalStatusEnum.AnalysisPending.valueOf()]:
    DeviceAnnotationStatusEnum.Pending,
  [SubjectTotalStatusEnum.AnalysisInitiated.valueOf()]:
    DeviceAnnotationStatusEnum.Initiated,
  [SubjectTotalStatusEnum.UnderReview.valueOf()]:
    DeviceAnnotationStatusEnum.UnderReview,
  [SubjectTotalStatusEnum.Reviewed.valueOf()]:
    DeviceAnnotationStatusEnum.Reviewed,
  [SubjectTotalStatusEnum.Completed.valueOf()]:
    DeviceAnnotationStatusEnum.Completed,
};

export const sortedSubjectTotalStatuses = [
  SubjectTotalStatusEnum.Available,
  SubjectTotalStatusEnum.AnalysisPending,
  SubjectTotalStatusEnum.AnalysisInitiated,
  SubjectTotalStatusEnum.UnderReview,
  SubjectTotalStatusEnum.Reviewed,
  SubjectTotalStatusEnum.Completed,
];

export const analysisStatusHashMap: any = {
  [SubjectTotalStatusEnum.Available]: (
    <MinusCircleOutlined style={{ color: theme.colors?.gray2, fontSize: 20 }} />
  ),
  [SubjectTotalStatusEnum.AnalysisPending]: <IconMenu pieChartQtr />,
  [SubjectTotalStatusEnum.AnalysisInitiated]: <IconMenu pieChartHalf />,
  [SubjectTotalStatusEnum.UnderReview]: <IconMenu underReview />,
  [SubjectTotalStatusEnum.Reviewed]: <IconMenu reviewed />,
  [SubjectTotalStatusEnum.Completed]: <IconMenu completed />,
};
