import theme, { GlobalStyle } from "lib/theme";
import { ThemeProvider } from "styled-components";
import AppRoutes from "./pages/index";
import { BrowserRouter } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import client from "ApolloClient/index";
import config from "lib/config";
import {
  useEffect,
  // useState
} from "react";
import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";
import "antd/dist/antd.css";

export default function App() {
  // const [scrollState, setScrollState] = useState(0);

  // useEffect(() => {
  //   window.scrollTo(0, scrollState);
  //   console.log("Triggered");
  //   return () => {
  //     setScrollState(window.pageYOffset);
  //     console.log("Triggered 2");
  //   };
  // }, []);

  useEffect(() => {
    if (config.NODE_ENV !== "development" && config.SENTRY_DSN) {
      Sentry.init({
        dsn: config.SENTRY_DSN,
        integrations: [new Integrations.BrowserTracing()],
        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        // release: 'growlab@' + process.env.COMMIT_REF,
        tracesSampleRate: 1.0,
        ignoreErrors: ["ResizeObserver loop limit exceeded"],
      });
    }
  }, []);

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </ThemeProvider>
    </ApolloProvider>
  );
}
