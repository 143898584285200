import _, { toNumber } from "lodash";
import moment from "moment";
import styled from "styled-components";
import Row from "components/common/Row";
import { useQuery } from "@apollo/client";
import Button from "components/common/Button";
import { Dispatch, SetStateAction } from "react";
import useUrlChange from "lib/hooks/useUrlChange";
import SwitchInput from "components/inputs/SwitchInput";
import PageSubtitle from "components/text/PageSubtitle";
import { FindSubjectSummariesQuery } from "generated/graphql";
import DevicesMultiSelect from "components/inputs/DevicesMultiSelect";
import UploadsMultiSelect from "components/inputs/UploadsMultiSelect";
import SessionsMultiSelect from "components/inputs/SessionsMultiSelect";
import SessionsEndDateSelect from "components/inputs/SessionsEndDateSelect";
import findSubjectSummaries from "ApolloClient/Queries/findSubjectSummaries";
import SessionsStartDateSelect from "components/inputs/SessionsStartDateSelect";

const FilterContainer = styled.div`
  width: 37%;
`;
const FilterContainer1 = styled.div`
  width: 15%;
`;
const FilterContainer2 = styled.div`
  width: 15%;
`;

export default function AdvancedFiltersRow({
  onFiltersChange,
  setShowAdvancedFilters,
}: {
  onFiltersChange: (values?: any) => void;
  setShowAdvancedFilters: Dispatch<SetStateAction<Boolean>>;
}) {
  const {
    query: {
      organizationIds: clientIds,
      siteIds,
      trialIds: studyIds,
      deviceIds,
      uploadIds,
      sessionIds,
      sessionStartDate,
      sessionEndDate,
      active,
      showFilters,
    },
    onUrlChange,
  } = useUrlChange();

  // const variables = {
  //   siteIds,
  //   clientIds,
  //   studyIds,
  //   // uploadIds,
  //   // deviceIds,
  //   // sessionIds,
  //   // sessionStartDate:
  //   //   sessionStartDate && moment(sessionStartDate).toISOString(),
  //   // sessionEndDate: sessionEndDate && moment(sessionEndDate).toISOString(),
  // };
  const leanVariablesDevice: any = Object.entries({
    siteIds,
    clientIds,
    studyIds,
    uploadIds,
    sessionIds,
    sessionStartDate:
      sessionStartDate && moment(sessionStartDate).toISOString(),
    sessionEndDate: sessionEndDate && moment(sessionEndDate).toISOString(),
  })
    .filter(([, value]) => {
      if (Array.isArray(value) && value.length < 1) {
        return false;
      }

      if (typeof value === "boolean") {
        return true;
      }

      return !!value;
    })
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

  const leanVariablesUpload: any = Object.entries({
    siteIds,
    clientIds,
    studyIds,
    deviceIds,
    sessionIds,
    sessionStartDate:
      sessionStartDate && moment(sessionStartDate).toISOString(),
    sessionEndDate: sessionEndDate && moment(sessionEndDate).toISOString(),
  })
    .filter(([, value]) => {
      if (Array.isArray(value) && value.length < 1) {
        return false;
      }

      if (typeof value === "boolean") {
        return true;
      }

      return !!value;
    })
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

  const leanVariablesSession: any = Object.entries({
    siteIds,
    clientIds,
    studyIds,
    uploadIds,
    deviceIds,
    sessionStartDate:
      sessionStartDate && moment(sessionStartDate).toISOString(),
    sessionEndDate: sessionEndDate && moment(sessionEndDate).toISOString(),
  })
    .filter(([, value]) => {
      if (Array.isArray(value) && value.length < 1) {
        return false;
      }

      if (typeof value === "boolean") {
        return true;
      }

      return !!value;
    })
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
  const { data: deviceIdsFilters, loading: deviceIdsFiltersLoading } =
    useQuery<FindSubjectSummariesQuery>(findSubjectSummaries, {
      variables: {
        ...leanVariablesDevice,
        sessionIds: leanVariablesDevice?.sessionIds?.map((value: string) =>
          parseInt(value)
        ),
        pagination: { limit: 9999 },
      },
    });
  const { data: uploadIdsFilters, loading: uploadIdsFiltersLoading } =
    useQuery<FindSubjectSummariesQuery>(findSubjectSummaries, {
      variables: {
        ...leanVariablesUpload,
        sessionIds: leanVariablesUpload?.sessionIds?.map((value: string) =>
          parseInt(value)
        ),
        pagination: { limit: 9999 },
      },
    });
  const { data: sessionIdsFilters, loading: sessionIdsFiltersLoading } =
    useQuery<FindSubjectSummariesQuery>(findSubjectSummaries, {
      variables: {
        ...leanVariablesSession,
        sessionIds: leanVariablesSession?.sessionIds?.map((value: string) =>
          parseInt(value)
        ),
        pagination: { limit: 9999 },
      },
    });

  const filteredDisplayableDeviceIds =
    _.uniqBy(
      _.flatten(
        deviceIdsFilters?.findSubjectSummaries?.items?.map(
          (item) => item?.sessions?.map((session) => session?.deviceId) ?? []
        )
      ),
      (value) => value
    ) ?? [];
  const filteredDisplayableUploadIds =
    _.uniqBy(
      _.flatten(
        uploadIdsFilters?.findSubjectSummaries?.items?.map(
          (item) => item?.sessions?.map((session) => session?.uploadId) ?? []
        )
      ),
      (value) => value
    ) ?? [];

  const filteredDisplayableSessionIds =
    _.uniqBy(
      _.flatten(
        sessionIdsFilters?.findSubjectSummaries?.items?.map(
          (item) =>
            item?.sessions?.map((session) =>
              session?.index && session?.index?.toString()?.length > 4
                ? session?.index?.toString()
                : session?.index?.toString().padStart(4, "0")
            ) ?? []
        )
      ),
      (value) => value
    ) ?? [];

  return (
    <div style={{ marginTop: 32, marginBottom: 24 }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          width: "98%",
          marginBottom: "12px",
        }}
      >
        <PageSubtitle style={{ margin: 0, fontSize: "17px" }}>
          Advanced Filters
        </PageSubtitle>
        <SwitchInput
          size="small"
          checked={showFilters?.toString()?.includes("true") ? true : undefined}
          onChange={(newActive) => {
            setShowAdvancedFilters(
              newActive?.toString()?.includes("true") ? true : false
            );
            onUrlChange({
              showFilters: newActive?.toString()?.includes("true")
                ? true
                : false,
            });
          }}
        />
      </div>
      {showFilters?.toString()?.includes("true") && (
        <div>
          <Row
            style={{ marginBottom: 16 }}
            align={"middle"}
            justify={"space-around"}
          >
            <FilterContainer>
              <UploadsMultiSelect
                loading={deviceIdsFiltersLoading}
                dataArray={filteredDisplayableUploadIds}
                onChange={(uploadIds: string[]) =>
                  onFiltersChange({ uploadIds })
                }
                value={uploadIds as string[]}
                organizationIds={clientIds as string[]}
                active={
                  Array.isArray(active)
                    ? !!active.find((x) => x === "true")
                    : active === "true"
                }
              />
            </FilterContainer>
            <FilterContainer1>
              <DevicesMultiSelect
                loading={uploadIdsFiltersLoading}
                dataArray={filteredDisplayableDeviceIds}
                onChange={(deviceIds: string[]) =>
                  onFiltersChange({ deviceIds })
                }
                value={deviceIds as string[]}
                organizationIds={clientIds as string[]}
                active={
                  Array.isArray(active)
                    ? !!active.find((x) => x === "true")
                    : active === "true"
                }
              />
            </FilterContainer1>
            <FilterContainer1>
              <SessionsMultiSelect
                loading={sessionIdsFiltersLoading}
                dataArray={filteredDisplayableSessionIds}
                onChange={(sessionIds: string[]) =>
                  onFiltersChange({ sessionIds })
                }
                value={sessionIds as string[]}
                organizationIds={clientIds as string[]}
                active={
                  Array.isArray(active)
                    ? !!active.find((x) => x === "true")
                    : active === "true"
                }
              />
            </FilterContainer1>
            <FilterContainer2>
              <SessionsStartDateSelect
                onChange={(sessionStartDate: Date) =>
                  onFiltersChange({ sessionStartDate })
                }
                value={
                  sessionStartDate
                    ? (moment(sessionStartDate).toDate() as Date)
                    : undefined
                }
                organizationIds={clientIds as string[]}
                active={
                  Array.isArray(active)
                    ? !!active.find((x) => x === "true")
                    : active === "true"
                }
              />
            </FilterContainer2>
            <FilterContainer2>
              <SessionsEndDateSelect
                onChange={(sessionEndDate: Date) =>
                  onFiltersChange({ sessionEndDate })
                }
                value={
                  sessionEndDate
                    ? (moment(sessionEndDate).toDate() as Date)
                    : undefined
                }
                organizationIds={clientIds as string[]}
                active={
                  Array.isArray(active)
                    ? !!active.find((x) => x === "true")
                    : active === "true"
                }
              />
            </FilterContainer2>
          </Row>
        </div>
      )}
      {(sessionStartDate ||
        sessionEndDate ||
        sessionIds ||
        deviceIds ||
        uploadIds) &&
        (showFilters?.toString()?.includes("true") ? true : undefined) && (
          <Button
            secondary
            onClick={() =>
              onFiltersChange({
                sessionStartDate: undefined,
                sessionEndDate: undefined,
                sessionIds: undefined,
                deviceIds: undefined,
                uploadIds: undefined,
              })
            }
          >
            Clear all
          </Button>
        )}
    </div>
  );
}
