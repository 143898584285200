import { Col, Row } from "antd";
import React from "react";

export type StudiesObject = {
  id: String;
  subjectName: String;
};

const AssignedSubjectsPopup = ({
  assignedSubjects,
}: {
  assignedSubjects: StudiesObject[];
}) => {
  return (
    <div>
      <h4>Can not delete reference.</h4>
      <h5>It is already assigned to the following subjects</h5>
      <Row>
        <Col span={12}>
          <h6>Subject Name</h6>
        </Col>
        <Col span={12}>
          <h6>Session ID</h6>
        </Col>
      </Row>
      {assignedSubjects.map((subject, index) => (
        <Row key={index}>
          <Col span={12}>
            <h6>
              <a href="/">{subject.subjectName}</a>
            </h6>
          </Col>
          <Col span={12}>
            <h6>
              <a href="/">{subject.id}</a>
            </h6>
          </Col>
        </Row>
      ))}
    </div>
  );
};

export default AssignedSubjectsPopup;
