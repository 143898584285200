// TOP LEVEL IMPORTS
import { useState } from "react";
// COMPONENTS
import TextInput from "components/inputs/TextInput";
import Button from "components/common/Button";
import FormItem from "components/common/FormItem";

export default function ForgotPasswordForm({
  loading,
  onSucessFullyValidated,
}: {
  loading: boolean;
  onSucessFullyValidated: any;
}) {
  const [email, setEmail] = useState<string | undefined>(undefined);
  const onSubmit = (e: any) => {
    e.preventDefault();
    onSucessFullyValidated(email);
  };
  return (
    <form onSubmit={onSubmit}>
      <FormItem>
        <TextInput
          placeholder="Email"
          type="email"
          inputMode="email"
          onChange={(e) => setEmail(e?.target?.value?.trim())}
          value={email}
        />
      </FormItem>
      <Button
        disabled={loading}
        loading={loading}
        onClick={onSubmit}
        fullWidth
        style={{ marginTop: 16, height: "40px" }}
      >
        Send reset email
      </Button>
    </form>
  );
}
