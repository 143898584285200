import { useState } from "react";
import {
  DeviceSessionIssueTypeEnum,
  UserTypeEnum,
  useCreateSubjectIssueMutation,
  useCreateSessionIssueMutation,
  Session,
  DeviceSessionIssue,
  SubjectSummary,
} from "generated/graphql";
import TextAreaInput from "components/inputs/TextAreaInput";
import Button from "components/common/Button";
import Modal from "components/common/Modal";
import message from "components/common/message";
import FormItem from "components/common/FormItem";
import useCurrentUser from "lib/hooks/useCurrentUser";
import IssueTypeSelectInput from "components/inputs/IssueTypeSelectInput";
import { gql, useApolloClient } from "@apollo/client";

interface Props {
  clientView?: boolean;
  visible?: boolean;
  deviceSessionId?: string;
  onCancel: () => void;
  subjectId?: string;
  sessionId?: string;
  subjectIdToDisplay?: string;
}

export default function IssueModal({
  visible,
  onCancel,
  deviceSessionId,
  clientView,
  subjectId,
  sessionId,
  subjectIdToDisplay,
}: Props) {
  const currentUser = useCurrentUser();
  const [description, setDescription] = useState<string | undefined>(undefined);
  const [issueType, setIssueType] = useState<
    DeviceSessionIssueTypeEnum | undefined
  >(clientView ? DeviceSessionIssueTypeEnum.ClientReported : undefined);
  const [createSubjectIssueMutation, { loading: creatingIssue }] =
    useCreateSubjectIssueMutation();
  const [createSessionIssueMutation, { loading: creatingSessionIssue }] =
    useCreateSessionIssueMutation();
  const apolloClient = useApolloClient();

  return (
    <Modal
      visible={visible}
      footer={null}
      onCancel={onCancel}
      title={`Report issue: ${subjectIdToDisplay}`}
      destroyOnClose
    >
      {currentUser?.userType === UserTypeEnum.SuperAdmin && !clientView && (
        <div style={{ marginBottom: 32 }}>
          <FormItem label="Type">
            <IssueTypeSelectInput
              value={issueType}
              onChange={(newValue) => setIssueType(newValue)}
            />
          </FormItem>
        </div>
      )}

      <div style={{ marginBottom: 32 }}>
        <TextAreaInput
          onChange={(e: any) => setDescription(e.target.value)}
          placeholder="Describe your issue..."
          style={{ marginTop: 0, marginBottom: 8 }}
          rows={6}
          value={description}
        />
      </div>

      <Button
        style={{
          width: 130,
          height: 40,
          fontSize: "12px !important",
          padding: 5,
        }}
        disabled={
          creatingIssue || creatingSessionIssue || !description || !issueType
        }
        loading={creatingIssue || creatingSessionIssue}
        onClick={async () => {
          try {
            if (sessionId) {
              const result = await createSessionIssueMutation({
                variables: {
                  input: {
                    sessionId,
                    description: description as string,
                    issueType,
                  },
                },
              });

              if (result?.data?.createSessionIssue?.id) {
                const sessionWithPreviousIssues =
                  apolloClient.readFragment<Session>({
                    id: `Session:${sessionId}`,
                    fragment: gql`
                      fragment SessionIssues on Session {
                        issues {
                          id
                          description
                          issueType
                        }
                      }
                    `,
                  });

                const newIssues = [
                  ...(sessionWithPreviousIssues?.issues || []),
                  {
                    __typename: "DeviceSessionIssue",
                    id: result.data.createSessionIssue.id,
                    description,
                    issueType,
                  } as DeviceSessionIssue,
                ];

                apolloClient.writeFragment({
                  id: `Session:${sessionId}`,
                  fragment: gql`
                    fragment SessionIssues on Session {
                      issues
                    }
                  `,
                  data: {
                    issues: newIssues,
                  },
                });
              }
            } else {
              if (subjectId) {
                const result = await createSubjectIssueMutation({
                  variables: {
                    input: {
                      description: description as string,
                      issueType,
                      subjectId,
                    },
                  },
                });

                if (result?.data?.createSubjectIssue?.id) {
                  const subjectWithPreviousIssues =
                    apolloClient.readFragment<SubjectSummary>({
                      id: `SubjectSummary:${subjectId}`,
                      fragment: gql`
                        fragment SubjectIssues on SubjectSummary {
                          issues {
                            id
                            description
                            issueType
                          }
                        }
                      `,
                    });

                  const newIssues = [
                    ...(subjectWithPreviousIssues?.issues || []),
                    {
                      __typename: "DeviceSessionIssue",
                      id: result.data.createSubjectIssue.id,
                      description,
                      issueType,
                    } as DeviceSessionIssue,
                  ];

                  apolloClient.writeFragment({
                    id: `SubjectSummary:${subjectId}`,
                    fragment: gql`
                      fragment SubjectIssues on SubjectSummary {
                        issues
                      }
                    `,
                    data: {
                      issues: newIssues,
                    },
                  });
                }
              }
            }
            setDescription(undefined);
            setIssueType(undefined);
            onCancel();
            message.success(`Your issue has been logged.`);
          } catch (err) {
            message.error("We couldn't log your issue. Please try again.");
            console.error(err);
          }
        }}
      >
        Submit Issue
      </Button>
    </Modal>
  );
}
