import { useState } from "react";
import JSZip from "jszip";

export interface ZipFileInput {
  name: string;
  file: Blob;
}

export default function useDownloadZip() {
  const [downloading, setDownloading] = useState(false);

  const downloadZip = async (files: ZipFileInput[], zipFilename?: string) => {
    const zip = new JSZip();
    const csvs: any = zip.folder(zipFilename?.replace(".zip", "") || "csvs");

    // append each file
    for (let i = 0; i < files.length; i++) {
      let obj = files[i];
      csvs.file(obj.name, obj.file, { base64: true });
    }

    const content = await zip.generateAsync({ type: "blob" });
    // In FF link must be added to DOM to be clicked
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(content);
    link.setAttribute("download", zipFilename || "data.zip");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    // }
    setDownloading(false);
  };

  return [downloadZip, downloading] as const;
}
