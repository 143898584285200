export const deviceStatusMap = {
  1: "Not Yet Provisioned",
  2: "Provisioned",
  3: "In Progress",
  4: "Session Completed",
  5: "Ready For Release",
  6: "Released",
};

export const getLabelFromStatus = (status) => {
  return deviceStatusMap[status];
};
