import Caption from "components/text/Caption";

export default function SiteStudies({
  studies,
  selectStudy,
}: {
  studies: any;
  selectStudy: any;
}) {
  return (
    <div
      style={{
        width: "95%",
        border: "rgba(0, 0, 0, 0.2) 0.5px solid",
        padding: "12px 8px",
      }}
    >
      {studies?.map((value: any) => (
        <Caption
          onClick={() => {
            selectStudy({ studyId: value?.id });
          }}
          style={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textDecoration: "underline",
            cursor: "pointer",
          }}
          color={"#127FBF"}
        >
          {value?.name}
        </Caption>
      ))}
    </div>
  );
}
