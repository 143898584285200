import StatsRow from "pages/client-home/StatsRow";
import SessionsTable, {
  subjectTableColEnum,
} from "components/common/SessionsTable";
import useUrlChange from "lib/hooks/useUrlChange";
import {
  Base64File,
  FindSubjectSummariesQuery,
  FindSubjectSummariesQueryVariables,
  GetSubjectReportFilesQuery,
  GetSubjectReportFilesQueryVariables,
  SortOrderEnum,
  SubjectSummary,
  useFindSubjectSummariesQuery,
  UserProfile,
} from "generated/graphql";
import { toNumber } from "lodash";
import { useCallback, useState } from "react";
import { message } from "antd";
import { useApolloClient } from "@apollo/client";
import findSubjectSummaries from "ApolloClient/Queries/findSubjectSummaries";
import { format } from "date-fns";
import useDownloadCSV from "lib/hooks/useDownloadCSV";
import getSubjectReportFiles from "ApolloClient/Queries/getSubjectReportFiles";
import { downloadBlob } from "lib/helpers/downloadBlob";

const DEFAULT_LIMIT = 10;

const transformSubjectSummaryForCsv = (summary: SubjectSummary[] = []) =>
  summary.map((s) => {
    const firstDayCoughs = s?.dailyReport?.firstDayCoughs ?? 0;
    const lastDayCoughs = s?.dailyReport?.lastDayCoughs ?? 0;
    const change = lastDayCoughs - firstDayCoughs;
    const subjectIssues = s?.issues?.length ?? 0;
    const subjectSessions =
      s?.sessions?.reduce(
        (acc, sess) => acc + (sess?.issues?.length ?? 0),
        0
      ) ?? 0;

    return {
      subjectId: s?.subjectId ?? "",
      status: s?.issues?.length ? "PossbileIssue" : "NoIssue",
      firstDayCoughs,
      lastDayCoughs,
      change,
      issues: subjectIssues + subjectSessions,
    };
  });
export interface FilterChangeParam {
  clientIds?: string[];
  studyIds?: string[];
  siteIds?: string[];
  sessionStatuses?: string[];
  annotationStatuses?: string[];
  issueTypes?: string[];
  uploadIds?: string[];
  deviceIds?: string[];
  sessionIds?: string[];
  sessionStartDate?: Date[];
  sessionEndDate?: Date[];
  statusData?: string;
  reload?: number;
}
export default function ClientHome({
  currentUser,
}: {
  currentUser: UserProfile;
}) {
  const { onUrlChange, query } = useUrlChange();

  const onFiltersChange = useCallback(
    (newFilters: FilterChangeParam) => {
      onUrlChange({
        ...newFilters,
        current: 1,
        skip: 0,
      });
    },
    [onchange]
  );

  const [sort, setSort] = useState<
    { field: subjectTableColEnum; order: SortOrderEnum }[]
  >([]);
  const { siteIds, trialIds, skip, limit, statusData, sessionId, deviceId } =
    query;

  const variables = {
    organizationIds: [currentUser?.organizationId as string],
    siteIds,
    trialIds,
    statusData,
    sessionStatuses: [6],
    pagination: {
      skip: toNumber(skip || 0),
      limit: toNumber(limit || DEFAULT_LIMIT),
    },
    active: true,
    sessionId: (sessionId?.[3] ? sessionId : "") as string,
    deviceId: (deviceId?.[3] ? deviceId : "") as string,
    sort,
  };

  const skipper = (() => {
    try {
      const skipNumber = toNumber(skip || 0);
      return Number.isSafeInteger(skipNumber) ? skipNumber : 1;
    } catch (error) {
      console.debug("[paginationSkip] We got a <<funny>> user:", error);
      return 1;
    }
  })();

  const paginationLimit = (() => {
    try {
      const limitNumber = toNumber(limit || DEFAULT_LIMIT);
      return Number.isSafeInteger(limitNumber) ? limitNumber : DEFAULT_LIMIT;
    } catch (error) {
      console.debug("[paginationLimit] We got a <<funny>> user:", error);
      return DEFAULT_LIMIT;
    }
  })();

  const offset = skipper * paginationLimit - paginationLimit;
  const currentPage = skipper > 0 ? skipper : 1;
  const didPageSizeChanged = (newPageSize: number) =>
    newPageSize !== paginationLimit;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const queryVariables = {
    statusData: statusData as string,
    clientIds: [currentUser?.organizationId as string],
    statuses: ["Completed"],
    pagination: {
      limit: paginationLimit,
      ...(offset > 0 ? { offset } : {}),
    },
  };

  if (variables.siteIds) {
    Object.assign(queryVariables, { siteIds: variables.siteIds });
  }

  if (variables.trialIds) {
    Object.assign(queryVariables, { studyIds: variables.trialIds });
  }
  const { data, loading, refetch, error } = useFindSubjectSummariesQuery({
    variables: sort[0]
      ? {
          ...queryVariables,
          statuses: ["Completed"],
          sort: {
            field: sort[0]?.field as any,
            order: sort[0]?.order as SortOrderEnum,
          },
        }
      : {
          ...queryVariables,
          statuses: ["Completed"],
        },
  });

  const reloadQuery = useCallback(() => {
    refetch(
      sort[0]
        ? {
            ...queryVariables,
            statuses: ["Completed"],
            sort: {
              field: sort[0]?.field as any,
              order: sort[0]?.order as SortOrderEnum,
            },
          }
        : {
            ...queryVariables,
            statuses: ["Completed"],
          }
    );
    onFiltersChange({ reload: Date.now() });
  }, [refetch, queryVariables, onFiltersChange]);

  const apolloClient = useApolloClient();
  const [downloadFile] = useDownloadCSV();

  const downloadSubjectsSummaries = useCallback(() => {
    (async () => {
      const messageKey = `msg-subject-summaries-download`;

      try {
        message.loading({
          content: "Downloading Subject Summaries...",
          key: messageKey,
          duration: 0,
        });
        const { data } = await apolloClient.query<
          FindSubjectSummariesQuery,
          FindSubjectSummariesQueryVariables
        >({
          query: findSubjectSummaries,
          variables: {
            ...queryVariables,
            pagination: {
              limit: 100000,
              offset: 0,
            },
          },
        });

        const summaries = (data.findSubjectSummaries?.items ??
          {}) as SubjectSummary[];

        const csvObjects = transformSubjectSummaryForCsv(summaries);
        downloadFile(
          csvObjects,
          `SubjectSummary-${format(Date.now(), "yyyy-MM-dd'T'HH-mm-ss")}.csv`
        );
        message.success({
          content: "Subjects' summaries downloaded.",
          key: messageKey,
          duration: 3,
        });
      } catch (error) {
        message.error({
          content: "There was a problem when fetching the Subjects' summaries.",
          key: messageKey,
          duration: 6,
        });
        console.error(error);
      }
    })();
  }, [apolloClient, queryVariables, downloadFile]);

  const downloadSubjectReportFiles = useCallback(
    (subjectId: string) => {
      (async () => {
        const messageKey = `msg-download-${subjectId}`;
        try {
          message.loading({
            content: "Downloading Report Files...",
            key: messageKey,
            duration: 0,
          });
          const { data } = await apolloClient.query<
            GetSubjectReportFilesQuery,
            GetSubjectReportFilesQueryVariables
          >({
            query: getSubjectReportFiles,
            variables: {
              subjectId,
            },
          });

          const { content = "", fileName = "" } =
            data.getSubjectReportFiles ?? ({} as Base64File);

          if (content.length === 0) {
            message.warn({
              content: "Report files are not available.",
              key: messageKey,
              duration: 6,
            });
            return;
          }

          const url = `data:application/zip;base64,${content}`;
          const raw = await fetch(url);
          const blob = await raw.blob();
          downloadBlob(blob, fileName);
          message.success({
            content: "Report Files downloaded.",
            key: messageKey,
            duration: 3,
          });
        } catch (error) {
          message.error({
            content: "There was a problem when fetching the Report Files.",
            key: messageKey,
            duration: 6,
          });
          console.error(error);
        }
      })();
    },
    [apolloClient]
  );

  const cols = [
    subjectTableColEnum.SubjectId,
    subjectTableColEnum.Status,
    subjectTableColEnum.FirstDayCoughs,
    subjectTableColEnum.LastDayCoughs,
    subjectTableColEnum.Change,
    subjectTableColEnum.Issues,
    subjectTableColEnum.clientDownload,
  ];

  return (
    <div>
      <StatsRow
        onFiltersChange={onFiltersChange}
        queryVariables={queryVariables}
      />
      <div style={{ marginTop: 40, width: "100%" }}>
        <SessionsTable
          reloadQuery={() => {
            reloadQuery();
          }}
          loading={loading}
          cols={cols}
          currentUser={currentUser}
          dataSource={data?.findSubjectSummaries?.items as any[]}
          sort={sort}
          variables={variables}
          onChange={(pagination: any, filters: any, sorter: any) => {
            if (sorter?.columnKey) {
              if (!sorter?.order) {
                setSort(
                  sort?.filter((item) => item.field !== sorter?.columnKey)
                );
              } else {
                setSort([
                  {
                    field: sorter?.columnKey,
                    order:
                      sorter?.order === "descend"
                        ? SortOrderEnum.Desc
                        : SortOrderEnum.Asc,
                  },
                ]);
              }
            }
            // onUrlChange({
            //   current: pagination?.current,
            //   limit: pagination?.pageSize,
            //   skip: pagination?.pageSize * (pagination.current - 1),
            // });
          }}
          pagination={{
            total: data?.findSubjectSummaries?.count,
            pageSize: toNumber(
              data?.findSubjectSummaries?.limit || DEFAULT_LIMIT
            ),
            current: currentPage,
            showSizeChanger: true,
            onChange(page: number = 1, pageSize: number = DEFAULT_LIMIT) {
              const limit = pageSize;
              const skip = didPageSizeChanged(pageSize) ? 0 : page;

              onUrlChange({
                limit,
                skip,
              });
            },
          }}
          clientView
          downloadSubjectsSummaries={downloadSubjectsSummaries}
          downloadSubjectReportFiles={downloadSubjectReportFiles}
        />
      </div>
    </div>
  );
}
