import { Col, Row, DatePicker, Divider, message } from "antd";
import { format } from "date-fns";
import theme from "lib/theme";
import {
  BaseCard,
  CardBoldText,
  CardRegularText,
  CardSubTitle,
} from "components/tables/AnalysisSessionTable/card-elements";
import { DATE_DISPLAY_FORMAT } from "components/tables/AnalysisSessionTable/data";
import styled from "styled-components";
import TruncateText from "components/common/TruncateText";
import moment from "moment";
import {
  SessionLog,
  useGetSessionLogsQuery,
  useUpdateSessionMutation,
} from "generated/graphql";

const { RangePicker } = DatePicker;

const StyledColTextAlignedCenter = styled(Col)`
  text-align: center;
`;

const StyledColTextAlignedRight = styled(Col)`
  text-align: right;
`;

const StyledSpan = styled.span`
  color: ${theme.colors.gray1};
  font-size: 12px;
`;

const safeDateHandler = (date: number | Date) => {
  try {
    return format(date, DATE_DISPLAY_FORMAT);
  } catch (error) {
    return "-";
  }
};

interface AdjustTimeCardProps {
  deviceStartTime: Date;
  deviceStopTime: Date;
  sessionStartTime: Date;
  sessionStopTime: Date;
  storage: string;
  hardware: string;
  firmware: string;
  upload: string;
  sessionId: string;
}

export const AdjustTimeCard = ({
  deviceStartTime,
  deviceStopTime,
  storage,
  hardware,
  firmware,
  upload,
  sessionId,
  sessionStartTime,
  sessionStopTime,
}: AdjustTimeCardProps) => {
  const [mutate] = useUpdateSessionMutation();
  const deviceStartTimeText = safeDateHandler(deviceStartTime);
  const deviceStopTimeText = safeDateHandler(deviceStopTime);
  const { data } = useGetSessionLogsQuery({
    variables: { deviceSessionId: sessionId },
  });
  const logAdded =
    data?.getSessionLogs?.find(
      (ele): ele is SessionLog => ele !== undefined && ele?.category === "Added"
    ) ?? null;
  const logAnalyzed =
    data?.getSessionLogs?.find(
      (ele): ele is SessionLog =>
        ele !== undefined && ele?.category === "Analyzed"
    ) ?? null;

  return (
    <BaseCard title="Session Info" titleColor={theme.colors.gray1}>
      <div style={{ marginTop: 5 }}>
        <Row gutter={8}>
          <Col span={4} />
          <StyledColTextAlignedCenter span={9}>
            <CardSubTitle>Start Time</CardSubTitle>
          </StyledColTextAlignedCenter>
          <StyledColTextAlignedCenter span={9}>
            <CardSubTitle>Stop Time</CardSubTitle>
          </StyledColTextAlignedCenter>
        </Row>
        <Row gutter={8}>
          <StyledColTextAlignedRight span={4}>
            <CardBoldText style={{ fontSize: "14px" }}>Device</CardBoldText>
          </StyledColTextAlignedRight>
          <StyledColTextAlignedCenter span={9}>
            <TruncateText text={deviceStartTimeText}>
              <CardRegularText>{deviceStartTimeText}</CardRegularText>
            </TruncateText>
          </StyledColTextAlignedCenter>
          <StyledColTextAlignedCenter span={9}>
            <TruncateText text={deviceStopTimeText}>
              <CardRegularText>{deviceStopTimeText}</CardRegularText>
            </TruncateText>
          </StyledColTextAlignedCenter>
        </Row>
        <Row gutter={8}>
          <StyledColTextAlignedRight span={4}>
            <TruncateText text="Selected">
              <CardBoldText style={{ fontSize: "14px" }}>Selected</CardBoldText>
            </TruncateText>
          </StyledColTextAlignedRight>
          <Col span={20}>
            <RangePicker
              size="small"
              showTime={{ format: "h:mm a" }}
              format="MM/DD/YY h:mm a"
              defaultValue={[moment(sessionStartTime), moment(sessionStopTime)]}
              onChange={(dates) => {
                (async () => {
                  try {
                    await mutate({
                      variables: {
                        input: {
                          id: sessionId,
                          startDate: dates?.[0]?.toDate(),
                          endDate: dates?.[1]?.toDate(),
                        },
                      },
                      refetchQueries: ["FindSubjectSummaries"],
                    });
                  } catch (error) {
                    message.warning("Failed to select time range.");
                  }
                })();
              }}
            />
          </Col>
        </Row>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: 10,
          width: "90%",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <div
          style={{
            marginTop: 10,
            width: "90%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <StyledSpan>HW: {hardware}</StyledSpan>
          <StyledSpan>FW: {firmware}</StyledSpan>
        </div>
        <div
          style={{
            marginTop: 10,
            width: "90%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          {logAdded && (
            <StyledSpan>
              Added:{" "}
              {new Date(logAdded?.createdAt).toLocaleString(undefined, {
                year: "2-digit",
                day: "2-digit",
                month: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              })}
            </StyledSpan>
          )}
          {logAnalyzed && (
            <StyledSpan>
              Analyzed:{" "}
              {new Date(logAnalyzed?.createdAt).toLocaleString(undefined, {
                year: "2-digit",
                day: "2-digit",
                month: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              })}
            </StyledSpan>
          )}
        </div>
      </div>
      <Divider style={{ height: 3, marginTop: 10, marginBottom: 5 }} />
      <div style={{ width: "90%", margin: "auto auto" }}>
        <div style={{ float: "left", width: "65%", textAlign: "left" }}>
          <TruncateText text={upload}>
            <StyledSpan>{upload}</StyledSpan>
          </TruncateText>
        </div>
        <div style={{ float: "left", width: "35%", textAlign: "right" }}>
          <StyledSpan>{storage.split("%")[0]}% Full</StyledSpan>
        </div>
      </div>
    </BaseCard>
  );
};
