import { Table } from "antd";
import Modal from "components/common/Modal";
// HOOKS
import ModalTitle from "components/text/ModalTitle";

export default function ShowDetailsModal({
  visible,
  onCancel,
}: {
  visible: boolean;
  onCancel: () => void;
}) {
  const dataSource = [
    {
      key: "1",
      session: "0003",
      reference: "S-123",
      session_start: "04/20/23 7:26 AM",
      session_end: "04/27/23 1:33 AM",
    },
    {
      key: "2",
      session: "0011",
      reference: "GU-13",
      session_start: "04/20/23 7:26 AM",
      session_end: "04/27/23 1:33 AM",
    },
    {
      key: "3",
      session: "0041",
      reference: "AF-3",
      session_start: "04/20/23 7:26 AM",
      session_end: "04/27/23 1:33 AM",
    },
  ];

  const columns = [
    {
      title: "Session",
      dataIndex: "session",
      key: "session",
    },
    {
      title: "Reference",
      dataIndex: "reference",
      key: "reference",
    },
    {
      title: "Session Start",
      dataIndex: "session_start",
      key: "session_start",
    },
    {
      title: "Session End",
      dataIndex: "session_end",
      key: "session_end",
    },
  ];

  return (
    <Modal width={"550px"} footer={null} visible={visible} onCancel={onCancel}>
      <ModalTitle>Report Details</ModalTitle>
      <div
        style={{
          paddingTop: 20,
          display: "flex",
          flexDirection: "column",
          gap: 8,
        }}
      >
        <Table pagination={false} dataSource={dataSource} columns={columns} />
      </div>
    </Modal>
  );
}
