import {
  ClockCircleOutlined,
  CheckCircleOutlined,
  OneToOneOutlined,
  UserOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import IconMenu from "lib/media/iconChartCard";
import theme from "lib/theme";

const iconStyles: any = {
  marginRight: 8,
  fontSize: 17,
  position: "relative",
  top: 2,
};

const sessionsIconHashMap: any = {
  5: (
    <ClockCircleOutlined
      style={{
        color: theme.colors?.primary5,
        ...iconStyles,
      }}
    />
  ),
  6: (
    <CheckCircleOutlined
      style={{ color: theme.colors?.success6, ...iconStyles }}
    />
  ),
  3: <UserOutlined style={{ color: theme.colors?.red1, ...iconStyles }} />,
  4: <IconMenu pieChartQtr />,
  2: (
    <MinusCircleOutlined
      style={{ color: theme.colors?.gray2, ...iconStyles }}
    />
  ),
  1: (
    <OneToOneOutlined
      style={{ color: theme.colors?.success4, ...iconStyles }}
    />
  ),
};

export default sessionsIconHashMap;
