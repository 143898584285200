import styled from "styled-components";
// COMPONENTS
import { UserOutlined } from "@ant-design/icons";
import { Menu, Dropdown, message } from "antd";
import { useLogoutMutation } from "generated/graphql";
import removeLocalStorage from "lib/helpers/removeLocalStorage";
import config from "lib/config";
import client from "ApolloClient/index";
import { useHistory } from "react-router-dom";

const AvatarContainer = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: ${(p) => p.theme.colors.neutral9};
  display: flex;
  align-items: center;
  position: relative;
  top: -4px;
  justify-content: center;
  .anticon {
    margin-right: 0px;
  }
`;

const Avatar = styled(UserOutlined)`
  margin: 0px;
  color: ${(p) => p.theme.colors.neutral5};
  font-size: 22px;
`;

export default function AvatarDropdown() {
  const [logout] = useLogoutMutation();
  const history = useHistory();
  const handleOnLogout = async () => {
    try {
      await logout();
      removeLocalStorage(config.authTokenName);
      removeLocalStorage(config.refreshTokenName);
      await client.resetStore();
      // let user know
      message.info("You've successfully logged out...");
      history.push(`/login`);
    } catch (err) {}
  };

  const menu = (
    <Menu style={{ width: 180 }}>
      <Menu.Item key="logout" onClick={handleOnLogout}>
        Log out
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown overlay={menu}>
      <AvatarContainer>
        <Avatar />
      </AvatarContainer>
    </Dropdown>
  );
}
