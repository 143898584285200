import styled from "styled-components";
// COMPONENTS
import FormItem from "components/common/FormItem";
import { Study, useUpdateStudyMutation } from "generated/graphql";
import message from "components/common/message";
import { omit } from "lodash";
import { Checkbox } from "antd";

const Form = styled.form`
  width: 220px;
  max-width: 100%;
  margin-bottom: 10px;
`;

export default function StudySettingsForm({
  defaultValues,
}: {
  defaultValues?: Study;
}) {
  const [UpdateStudyMutation] = useUpdateStudyMutation();

  const handleChange = async (newValues: any) => {
    try {
      if (!defaultValues?.id) return;
      await UpdateStudyMutation({
        variables: {
          input: {
            id: defaultValues?.id,
            active: newValues.active,
            metrics: newValues?.metrics.map((item: any) => {
              return omit(item, ["__typename"]);
            }),
          },
        },
      });
      message.success("Your changes were saved");
    } catch (err) {
      console.log(err);
      message.error("There was an error saving your changes");
    }
  };

  let metricsCopy = [...(defaultValues?.metrics ? defaultValues?.metrics : [])];

  let metricsSorted = metricsCopy?.sort((a, b) => {
    return a.key.localeCompare(b.key);
  });

  return (
    <Form>
      {defaultValues &&
        metricsSorted?.map((option) => (
          <FormItem
            label={option.label as string}
            singleLineLayout={true}
            key={`${defaultValues.id}-${option.key}`}
            containerProps={{
              style: {
                width: "100%",
              },
            }}
          >
            <Checkbox
              onChange={(checked) => {
                handleChange({
                  metrics: [
                    ...(defaultValues?.metrics?.filter(
                      (metric) => metric?.key !== option?.key
                    ) || []),
                    {
                      key: option?.key,
                      value: checked.target.checked,
                      label: option?.label,
                    },
                  ],
                });
              }}
              defaultChecked={option?.value ?? false}
            />
          </FormItem>
        ))}
    </Form>
  );
}
